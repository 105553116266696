import React from 'react';
import './SubmissionScreen.css'; // CSS-Datei für Styling
import './ArtistDashboard.css';
import DashboardNav from './DashboardNav';
import MySongs from './MySongs';
import DashboardMobileNav from './DashboardMobileNav';
import SongsAndAwards from './SongsAndAwards';
import AllPages from './Page/AllPages'; // Importiere die AllPages-Komponente
import NewMobileHeader from '../Pages/NewMobileHeader';
import NewMobileHeader2 from '../Pages/NewMobileHeader2';

function ArtistDashboard({ user }) {
  return (
    <div className="artist-dashboard">
      <DashboardNav />
      <NewMobileHeader2 />
´      <DashboardMobileNav />
      {user.isExpert ? (
        <AllPages /> // Zeige AllPages an, wenn der Benutzer ein Experte ist
      ) : (
        <SongsAndAwards /> // Zeige SongsAndAwards an, wenn der Benutzer kein Experte ist
      )}
    </div>
  );
}

export default ArtistDashboard;

import React from 'react';

function CollaboratorList({ onChange, value }) {
  // List of potential collaborators for a musician
  const collaboratorItems = [
    'Songwriter', 'Producer', 'Mixing Engineer', 'Mastering Engineer',
    'Vocal Coach', 'Instrumentalist', 'Background Vocalist', 'Sound Designer',
    'Music Video Director', 'Choreographer', 'Stylist', 'Photographer',
    'Graphic Designer', 'Public Relations Manager', 'Social Media Manager',
    'Music Publisher', 'A&R Representative', 'Music Lawyer', 'Tour Manager',
    'Live Sound Engineer', 'Booking Agent', 'Merchandiser', 'Fan Community Manager',
    'Music Therapist', 'Music Journalist', 'Podcast Host', 'Event Promoter',
    'Venue Owner', 'Music Educator', 'Instrument Technician'
  ];

  return (
    <select onChange={onChange} value={value} className="equipment-select">
      <option value="">Select Collaborator...</option>
      {collaboratorItems.map((item, index) => (
        <option key={index} value={item}>{item}</option>
      ))}
    </select>
  );
}

export default CollaboratorList;

import React from 'react';
import './GenerativeOrbAnimation.css';

const GenerativeOrbAnimation = () => {
  // Dies ist nur eine Präsentationskomponente, die keine Logik enthält.
  return (
<div className="component-wrapper">
    <div class="orb orb-1"></div>
    <div class="orb orb-2"></div>
    <div class="orb orb-3"></div>
    <div class="orb orb-4"></div>
</div>

  );
};

export default GenerativeOrbAnimation;

import React, { useState } from 'react';
import { ref as databaseRef, update } from 'firebase/database';
import { database } from "../firebaseConfig";
import './ListArtistActions.css'; // Spezifische CSS für das Modal

function ListArtistActions({ artist, onClose, onSave }) {
  const [isPremium, setIsPremium] = useState(artist.isPremium);
  const [songCredits, setSongCredits] = useState(artist.songCredits || 0);
  const [collabCredits, setCollabCredits] = useState(artist.collabCredits || 0);

  const handleSave = () => {
    const artistRef = databaseRef(database, `Artists/${artist.id}`);
    const updatedArtist = {
      isPremium,
      songCredits,
      collabCredits,
    };

    update(artistRef, updatedArtist)
      .then(() => {
        console.log(`Künstler ${artist.id} erfolgreich aktualisiert.`);
        onSave({ ...artist, ...updatedArtist });
      })
      .catch((error) => {
        console.error("Fehler beim Aktualisieren des Künstlers: ", error);
      });
  };

  return (
    <div className="laa-modal-backdrop">
      <div className="laa-modal-content">
        <h2>Künstleraktionen: {artist.artistName}</h2>
        
        <label className="laa-modal-label">
          <input
            type="checkbox"
            checked={isPremium}
            onChange={(e) => setIsPremium(e.target.checked)}
          />
          Premium-Status
        </label>

        <div>
          <label className="laa-modal-label">
            Song Credits:
            <input
              type="number"
              value={songCredits}
              onChange={(e) => setSongCredits(parseInt(e.target.value) || 0)}
              className="laa-modal-input"
            />
          </label>
        </div>

        <div>
          <label className="laa-modal-label">
            Collab Credits:
            <input
              type="number"
              value={collabCredits}
              onChange={(e) => setCollabCredits(parseInt(e.target.value) || 0)}
              className="laa-modal-input"
            />
          </label>
        </div>

        <button onClick={handleSave} className="laa-modal-button">Speichern</button>
        <button onClick={onClose} className="laa-modal-button laa-modal-close">Schließen</button>
      </div>
    </div>
  );
}

export default ListArtistActions;

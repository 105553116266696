import React, { useEffect, useState } from 'react';
import { ref as databaseRef, onValue, remove, update } from 'firebase/database';
import { database } from "../firebaseConfig";
import './ListArtists.css';
import EditArtistModal from './EditArtistModal';
import ArtistStats from './ArtistStats';
import ServiceStats from './ServiceStats';
import ListArtistActions from './ListArtistActions';
import ArtistAnalysis from './ArtistAnalysis'; // Import the ArtistAnalysis component

function ListArtists() {
  const [artists, setArtists] = useState([]);
  const [filteredArtists, setFilteredArtists] = useState([]);
  const [paginatedArtists, setPaginatedArtists] = useState([]); // State for paginated artists
  const [currentPage, setCurrentPage] = useState(1);
  const [artistsPerPage] = useState(20); // Number of artists per page
  const [searchQuery, setSearchQuery] = useState(''); // State for search query
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [currentArtistId, setCurrentArtistId] = useState(null);
  const [isActionModalOpen, setIsActionModalOpen] = useState(false); // Modal for actions
  const [currentArtist, setCurrentArtist] = useState(null); // Current artist for actions

  const openEditModal = (artistId) => {
    setCurrentArtistId(artistId);
    setIsEditModalOpen(true);
  };

  const openActionModal = (artist) => {
    setCurrentArtist(artist);
    setIsActionModalOpen(true);
  };

  const closeActionModal = () => {
    setIsActionModalOpen(false);
    setCurrentArtist(null);
  };

  const deleteArtist = (artistId) => {
    const isConfirmed = window.confirm("Sind Sie sicher, dass Sie diesen Künstler löschen möchten?");
    if (isConfirmed) {
      const artistRef = databaseRef(database, `Artists/${artistId}`);
      remove(artistRef)
        .then(() => {
          console.log(`Künstler ${artistId} erfolgreich gelöscht.`);
        })
        .catch((error) => {
          console.error("Fehler beim Löschen des Künstlers: ", error);
        });
    }
  };

  const togglePremiumStatus = (artist) => {
    const updatedStatus = !artist.isPremium;
    const artistRef = databaseRef(database, `Artists/${artist.id}`);
    update(artistRef, { isPremium: updatedStatus })
      .then(() => {
        console.log(`Premium-Status für ${artist.artistName} erfolgreich auf ${updatedStatus ? 'Ja' : 'Nein'} gesetzt.`);
      })
      .catch((error) => {
        console.error("Fehler beim Aktualisieren des Premium-Status: ", error);
      });
  };

  useEffect(() => {
    const artistsRef = databaseRef(database, 'Artists');
    onValue(artistsRef, (snapshot) => {
      const artistsData = snapshot.val();
      let artistsList = artistsData ? Object.keys(artistsData).map(key => {
        const artist = {
          id: key,
          ...artistsData[key],
        };
        
        // Extract first session timestamp
        const sessionTimestamps = Object.values(artist.sessionTimestamps || {});
        const firstSessionTimestamp = sessionTimestamps.length ? Math.min(...sessionTimestamps) : null;

        // Convert timestamp to human-readable format
        const firstSessionDate = firstSessionTimestamp ? new Date(firstSessionTimestamp).toLocaleDateString() : 'N/A';

        // Convert lastSessionTimestamp to human-readable format
        const lastSessionDate = artist.lastSessionTimestamp ? new Date(artist.lastSessionTimestamp).toLocaleDateString() : 'N/A';

        // Determine if the artist is a Superuser (10+ sessions)
        const isSuperuser = artist.sessionCounter >= 10 ? 'Superuser' : '';

        // Check if hasSubmitted is true or false
        const hasSubmitted = artist.hasSubmitted ? 'Ja' : 'Nein';

        // Handle genre logic (array or single value)
        const genre = Array.isArray(artist.genre) ? artist.genre.join(', ') : artist.genre;

        // Return artist data with first session timestamp, last session login, session count, Superuser status, hasSubmitted, and genre
        return {
          ...artist,
          firstSessionDate,
          lastSessionDate,
          sessionCounter: artist.sessionCounter || 0, // Default to 0 if sessionCounter is not present
          isSuperuser, // Add Superuser status
          hasSubmitted, // Add hasSubmitted status
          genre, // Add genre display (either single or comma-separated values)
        };
      }) : [];

      // Sort by signup date
      artistsList.sort((a, b) => {
        return new Date(b.signupTimestamp) - new Date(a.signupTimestamp);
      });

      setArtists(artistsList); // Store full list of artists
      setFilteredArtists(artistsList); // Initialize filtered list
      paginate(artistsList, 1); // Paginate the initial list
    });
  }, []);

  // Function to handle search input changes
  const handleSearch = (e) => {
    const query = e.target.value.toLowerCase();
    setSearchQuery(query);
  
    // Filter artists by artistName, name, or email, checking if each exists
    const filtered = artists.filter(artist => 
      (artist.artistName && artist.artistName.toLowerCase().includes(query)) ||
      (artist.name && artist.name.toLowerCase().includes(query)) ||
      (artist.email && artist.email.toLowerCase().includes(query))
    );
    
    setFilteredArtists(filtered);
    paginate(filtered, 1); // Apply pagination after search
  };
  

  // Pagination logic
  const paginate = (artistList, pageNumber) => {
    const indexOfLastArtist = pageNumber * artistsPerPage;
    const indexOfFirstArtist = indexOfLastArtist - artistsPerPage;
    const currentArtists = artistList.slice(indexOfFirstArtist, indexOfLastArtist);
    setPaginatedArtists(currentArtists);
    setCurrentPage(pageNumber);
  };

  // Render pagination buttons
  const renderPagination = () => {
    const totalPages = Math.ceil(filteredArtists.length / artistsPerPage);
    const buttons = [];
    for (let i = 1; i <= totalPages; i++) {
      buttons.push(
        <button key={i} onClick={() => paginate(filteredArtists, i)} className={i === currentPage ? 'active' : ''}>
          {i}
        </button>
      );
    }
    return buttons;
  };
  
  return (
    <div className="list-artists-container">
      <ArtistStats />
      <ServiceStats />
      {/* Pass the full artist data to the ArtistAnalysis component */}
      <ArtistAnalysis artists={artists} /> 

      {/* Search input */}
      <input
        type="text"
        placeholder="Search by artist name or email"
        value={searchQuery}
        onChange={handleSearch}
        className="search-input"
        style={{
          padding: '10px',
          margin: '20px 0',
          width: '100%',
          borderRadius: '5px',
          border: '1px solid #ccc'
        }}
      />

      <h1>Künstlerliste</h1>
      <div className="pagination-controls">
        {renderPagination()}
      </div>
      <div className="table-scroll-container">
      <table className="list-artists-table">
        <thead>
          <tr>
          {isEditModalOpen && <EditArtistModal artistId={currentArtistId} onClose={() => setIsEditModalOpen(false)} />}
          {isActionModalOpen && currentArtist && (
            <ListArtistActions
              artist={currentArtist}
              onClose={closeActionModal}
              onSave={(updatedArtist) => {
                // Update the artist list after saving
                setArtists(artists.map(a => a.id === updatedArtist.id ? updatedArtist : a));
                closeActionModal();
              }}
            />
          )}
            <th>Edit Artist</th>
            <th>Künstlername</th>
            <th>Name</th>
            <th>Land</th>
            <th>Email</th>
            <th>Settings</th>
            <th>Premium</th>
            <th>Song Credits</th>
            <th>Collab Credits</th>
            <th>First Session</th>
            <th>Letztes Login</th>
            <th>Sitzungen</th>
            <th>Superuser</th>
            <th>Submitted</th>
            <th>Genre</th>
            <th>Auszeichnungen</th>
            <th>Nominiert</th>
            <th>ID</th>
            <th>Aktionen</th>
            <th>Löschen</th>
          </tr>
        </thead>
        <tbody>
          {paginatedArtists.map((artist) => (
            <tr key={artist.id}>
              <td><button onClick={() => openEditModal(artist.id)}>Edit Artist</button></td>
              <td>{artist.artistName}</td>
              <td>{artist.name}</td>
              <td>{artist.country}</td>
              <td>{artist.email}</td>
              <td>{artist.hasSettings ? 'Ja' : 'Nein'}</td>
              <td>
                <label className="switch">
                  <input 
                    type="checkbox" 
                    checked={artist.isPremium} 
                    onChange={() => togglePremiumStatus(artist)} 
                  />
                  <span className="slider round"></span>
                </label>
              </td>
              <td>{artist.songCredits || 0}</td>
              <td>{artist.collabCredits || 0}</td>
              <td>{artist.firstSessionDate}</td>
              <td>{artist.lastSessionDate}</td>
              <td>{artist.sessionCounter}</td>
              <td>{artist.isSuperuser}</td>
              <td>{artist.hasSubmitted}</td>
              <td>{artist.genre || 'N/A'}</td>
              <td>{artist.numberAwards}</td>
              <td>{artist.numberNominated}</td>
              <td>{artist.id}</td>
              <td><button onClick={() => openActionModal(artist)}>Aktionen</button></td>
              <td><button onClick={() => deleteArtist(artist.id)} style={{ marginLeft: "10px"}}>Löschen</button></td>
            </tr>
          ))}
        </tbody>
      </table>

      {/* Render pagination controls */}
    
    </div>
    </div>
  );
}

export default ListArtists;

import React, { useState, useEffect } from 'react';
import { ref, get, set } from 'firebase/database';
import { database } from '../firebaseConfig';
import './AutoMessages.css';

function AutoMessage({ path, messageType, specialFields = [] }) {
  const [senderUserId, setSenderUserId] = useState('');
  const [waitTimeInHours, setWaitTimeInHours] = useState('');
  const [messageInput, setMessageInput] = useState(''); // For new message input
  const [messages, setMessages] = useState([]); // Store multiple messages
  const [htmlSnippet, setHtmlSnippet] = useState(''); // Special field for MoesardMail
  const [subject, setSubject] = useState(''); // Special field for MoesardMail
  const [isEditing, setIsEditing] = useState(false); // Toggle between edit and view modes
  const [isLoading, setIsLoading] = useState(true);
  const [isActive, setIsActive] = useState(true); // Add toggle for message activity

  // Function to load existing data from Firebase
  const loadData = async () => {
    const dataRef = ref(database, path);
    try {
      const snapshot = await get(dataRef);
      if (snapshot.exists()) {
        const data = snapshot.val();
        setSenderUserId(data.senderUserId || '');
        setWaitTimeInHours(data.waitTimeInHours || '');
        setIsActive(data.isActive !== undefined ? data.isActive : true); // Load isActive status
        if (specialFields.includes('htmlSnippet')) setHtmlSnippet(data.htmlSnippet || '');
        if (specialFields.includes('subject')) setSubject(data.subject || '');
        if (!specialFields.length) setMessages(Array.isArray(data.messages) ? data.messages : []);
      } else {
        console.log(`No data available for ${messageType}`);
      }
    } catch (error) {
      console.error(`Error loading data from ${messageType}:`, error);
    } finally {
      setIsLoading(false);
    }
  };

  // Load data on component mount
  useEffect(() => {
    loadData();
  }, [path]);

  // Function to handle form submission and save data
  const handleSaveData = async () => {
    if (!senderUserId || !waitTimeInHours) {
      alert('Please fill in all required fields.');
      return;
    }

    const dataRef = ref(database, path);
    const updatedData = {
      senderUserId,
      waitTimeInHours,
      isActive, // Save isActive status
      ...(specialFields.includes('htmlSnippet') && { htmlSnippet }), // Only include if the field exists
      ...(specialFields.includes('subject') && { subject }), // Only include if the field exists
      ...(!specialFields.length && { messages }), // Only include if not using special fields
    };

    try {
      await set(dataRef, updatedData); // Overwrite the entire object at the given path
      alert(`${messageType} data saved successfully!`);
      setIsEditing(false); // Exit edit mode after saving
    } catch (error) {
      console.error(`Error saving ${messageType} data:`, error);
      alert(`Failed to save ${messageType} data.`);
    }
  };

  if (isLoading) {
    return <p>Loading data for {messageType}...</p>;
  }

  // Function to add a new message (used only if specialFields is empty)
  const addMessage = () => {
    if (messageInput.trim() === '') return;
    setMessages([...messages, messageInput]); // Add new message to the list
    setMessageInput(''); // Clear input field
  };

  // Function to delete a message
  const deleteMessage = (index) => {
    const updatedMessages = messages.filter((_, i) => i !== index);
    setMessages(updatedMessages);
  };

  return (
    <div className="auto-messages-wrapper">
      {/* Toggle between edit and view modes */}
      <button className="auto-messages-toggle-btn" onClick={() => setIsEditing(!isEditing)}>
        {isEditing ? 'Cancel Edit' : 'Edit'}
      </button>

      {isEditing ? (
        <div className="auto-messages-form">
          <label>Sender User ID</label>
          <input
            type="text"
            value={senderUserId}
            onChange={(e) => setSenderUserId(e.target.value)}
            placeholder="Enter sender user ID"
          />

          <label>Wait Time (in Hours)</label>
          <input
            type="number"
            step="0.1"
            value={waitTimeInHours}
            onChange={(e) => setWaitTimeInHours(e.target.value)}
            placeholder="Enter wait time in hours"
          />

          {/* Toggle for isActive */}
          <label>
            <input 
              type="checkbox" 
              checked={isActive} 
              onChange={() => setIsActive(!isActive)} 
            />
            Active
          </label>

          {specialFields.includes('htmlSnippet') && (
            <>
              <label>HTML Snippet</label>
              <textarea
                value={htmlSnippet}
                onChange={(e) => setHtmlSnippet(e.target.value)}
                placeholder="Enter HTML Snippet"
              />
            </>
          )}

          {specialFields.includes('subject') && (
            <>
              <label>Subject</label>
              <input
                type="text"
                value={subject}
                onChange={(e) => setSubject(e.target.value)}
                placeholder="Enter subject"
              />
            </>
          )}

          {!specialFields.length && (
            <>
              <label>Add Message</label>
              <input
                type="text"
                value={messageInput}
                onChange={(e) => setMessageInput(e.target.value)}
                placeholder="Enter a message"
              />
              <button onClick={addMessage}>Add Message</button>

              <div className="auto-messages-list">
                <h3>Messages</h3>
                {messages.length > 0 ? (
                  messages.map((msg, index) => (
                    <div key={index} className="auto-message-item">
                      <p>{msg}</p>
                      <button className="auto-messages-delete-btn" onClick={() => deleteMessage(index)}>
                        Delete
                      </button>
                    </div>
                  ))
                ) : (
                  <p>No messages yet.</p>
                )}
              </div>
            </>
          )}

          <button onClick={handleSaveData}>Save {messageType}</button>
        </div>
      ) : (
        <div className="auto-messages-view">
          <p><strong>Sender User ID:</strong> {senderUserId || 'N/A'}</p>
          <p><strong>Wait Time (in Hours):</strong> {waitTimeInHours || 'N/A'}</p>
          <p><strong>Active:</strong> {isActive ? 'Yes' : 'No'}</p>

          {specialFields.includes('htmlSnippet') && (
            <p><strong>HTML Snippet:</strong> {htmlSnippet || 'N/A'}</p>
          )}

          {specialFields.includes('subject') && (
            <p><strong>Subject:</strong> {subject || 'N/A'}</p>
          )}

          {!specialFields.length && (
            <>
              <h3>Messages</h3>
              {messages.length > 0 ? (
                messages.map((msg, index) => <p key={index}>{msg}</p>)
              ) : (
                <p>No messages saved.</p>
              )}
            </>
          )}
        </div>
      )}
    </div>
  );
}

export default AutoMessage;

import React, { useState, useEffect } from 'react';
import { ref as databaseRef, push, set, update, get } from 'firebase/database';
import { database, auth, storage } from '../../firebaseConfig';
import './ChatList.css'
import ImageUploadComponent from './ImageUploadComponent';
import SuperChat from './SuperChat';
import ImageUploadComponentChat from './ImageUploadComponent';

const ChatInput = ({ chatId }) => {
    const [inputMessage, setInputMessage] = useState('');
    const [imageUploadUrl, setImageUploadUrl] = useState('');
    const [uploading, setUploading] = useState(false);
    const [typingTimeout, setTypingTimeout] = useState(null);
    const [resetImageUpload, setResetImageUpload] = useState(false);
    const [receiverId, setReceiverId] = useState(null); // Hier speichern wir die receiverId

    useEffect(() => {
        const fetchChatData = async () => {
            const currentUser = auth.currentUser;
            if (!currentUser) {
                console.error('No user logged in');
                return;
            }

            const chatRef = databaseRef(database, `Chats/${chatId}`);
            const chatSnapshot = await get(chatRef);
            if (chatSnapshot.exists()) {
                const chatData = chatSnapshot.val();
                // Bestimmen, wer der Empfänger ist
                const otherUserId = chatData.initiatorId === currentUser.uid ? chatData.recipientId : chatData.initiatorId;
                setReceiverId(otherUserId); // Setzen der receiverId
            }
        };

        fetchChatData();
    }, [chatId]);

    const handleUploadSuccess = (url) => {
        setImageUploadUrl(url);
    };

    const updateTypingStatus = () => {
        const typingRef = databaseRef(database, `Chats/${chatId}/typing/${auth.currentUser.uid}`);
        set(typingRef, true);

        if (typingTimeout) {
            clearTimeout(typingTimeout);
        }

        const newTimeout = setTimeout(() => {
            set(typingRef, false);
        }, 3000);

        setTypingTimeout(newTimeout);
    };

    const handleTyping = (value) => {
        setInputMessage(value);
        updateTypingStatus();
    };

    const handleSendMessage = async () => {
        if (!inputMessage && !imageUploadUrl) {
            console.error('No message or image to send');
            return;
        }

        const currentUser = auth.currentUser;
        if (!currentUser) {
            console.error('No user logged in');
            return;
        }

        const messagesRef = databaseRef(database, `Chats/${chatId}/messages`);
        const newMessageRef = push(messagesRef);
        const timestamp = new Date().toISOString();

        await set(newMessageRef, {
            message: inputMessage,
            imageUrl: imageUploadUrl,
            senderId: currentUser.uid,
            type: "normal",
            timestamp,
            readStatus: false  // Nachricht als ungelesen markieren
        });

        // Update des letzten Nachrichten-Zeitstempels im Chat-Objekt
        const chatRef = databaseRef(database, `Chats/${chatId}`);
        await update(chatRef, {
            lastMessageAt: timestamp  // Aktualisieren des Zeitstempels der letzten Nachricht
        });

        setInputMessage('');  // Setzt den Text-Input zurück
        setImageUploadUrl('');  // Setzt das Bild-URL-Input zurück
        setResetImageUpload(!resetImageUpload); // Trigger zum Zurücksetzen der ImageUploadComponent
        set(databaseRef(database, `Chats/${chatId}/typing/${currentUser.uid}`), false); // Optional: Schaltet den Status des Tippen-Indikators ab
    };

    return (
        <div className="chat-message-input">
            <textarea
                value={inputMessage}
                onChange={e => handleTyping(e.target.value)}
                placeholder="Type here"
            />
            {/* receiverId an SuperChat übergeben */}
            <SuperChat onUploadSuccess={handleUploadSuccess} resetTrigger={resetImageUpload} chatId={chatId} receiverId={receiverId} />
            <ImageUploadComponentChat onUploadSuccess={handleUploadSuccess} />
            <button className="chat-image-send-button" onClick={handleSendMessage}><img src="../../img/sendo.svg" alt="" /></button>
        </div>
    );
};

export default ChatInput;

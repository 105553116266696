import React, { useRef, useState, useEffect } from 'react';
import { ref as storageRef, uploadBytesResumable, getDownloadURL } from 'firebase/storage';
import { storage } from '../../firebaseConfig';
import Resizer from 'react-image-file-resizer';
import './ChatWindow.css'; // Pfad zur CSS-Datei
import './ImageUploadComponentChat.css'; // Pfad zur CSS-Datei


const ImageUploadComponentChat = ({ onUploadSuccess, resetTrigger }) => {
    const [uploading, setUploading] = useState(false);
    const [imageUploadUrl, setImageUploadUrl] = useState(''); // Definieren der state variable für die Bild-URL
    const fileInputRef = useRef(null); // Ref für den Zugriff auf den File-Input

    useEffect(() => {
        if (resetTrigger) {
            setImageUploadUrl(''); // Setzt das Vorschaubild zurück
        }
    }, [resetTrigger]);

    const handleFileChange = async (event) => {
        const file = event.target.files[0];
        if (!file) {
            console.error('No file selected.');
            return;
        }

        const fileSizeLimit = 10 * 1024 * 1024; // 10 MB Limit
        if (file.size > fileSizeLimit) {
            alert('File is too big. The limit is 10 MB.');
            return;
        }

        setUploading(true);
        Resizer.imageFileResizer(
            file,
            1300, // max width
            1300, // max height
            'JPEG', // compress format
            80, // quality
            0, // rotation
            uri => {
                uploadImage(uri);
            },
            'blob'
        );
    };

    const uploadImage = (blob) => {
        const fileRef = storageRef(storage, `chatImages/${new Date().getTime()}.jpeg`);
        const uploadTask = uploadBytesResumable(fileRef, blob);

        uploadTask.on('state_changed',
            () => {},
            error => {
                console.error('Upload failed:', error);
                setUploading(false);
            },
            () => {
                getDownloadURL(uploadTask.snapshot.ref).then(downloadURL => {
                    setImageUploadUrl(downloadURL); // Setzen der Bild-URL nach erfolgreichem Hochladen
                    onUploadSuccess(downloadURL);
                    setUploading(false);
                });
            }
        );
    };

    const openFileDialog = () => {
        fileInputRef.current.click(); // Triggert den Klick auf den versteckten File-Input
    };

    return (
        <div>
            <input
                ref={fileInputRef}
                type="file"
                accept="image/*"
                onChange={handleFileChange}
                style={{ display: 'none' }}
            />
            <button className="chat-image-upload" onClick={openFileDialog}>
                <img src='../../img/upload.svg' alt="Upload Image" />
            </button>
            {uploading && <div style={{ bottom: '15px', right: '170px', width: '30px', height: '30px' }} className="spinner-4"></div>}
            {imageUploadUrl && <img src={imageUploadUrl} className="chat-prev-upladed-img" alt="Uploaded image preview" />}
        </div>
    );
};

export default ImageUploadComponentChat;

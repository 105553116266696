import { useEffect } from 'react';
import { ref as databaseRef, set, onDisconnect, get, update } from 'firebase/database';
import { auth, database } from '../firebaseConfig';
import { v4 as uuidv4 } from 'uuid';
import { useLocation } from 'react-router-dom'; // Now safely use it

const useUserOnlineStatus = () => {
  const FIVE_MINUTES = 5 * 60 * 1000;
  const location = useLocation(); // Use this to track the current route

  useEffect(() => {
    const updateOnlineStatusAndSession = async () => {
      const user = auth.currentUser;

      if (user && user.emailVerified) {
        const userStatusRef = databaseRef(database, `/Artists/${user.uid}/isUserOnline`);
        const sessionCounterRef = databaseRef(database, `/Artists/${user.uid}/sessionCounter`);
        const sessionTimestampsRef = databaseRef(database, `/Artists/${user.uid}/sessionTimestamps`);
        const lastSessionTimestampRef = databaseRef(database, `/Artists/${user.uid}/lastSessionTimestamp`);
        const lastPageRef = databaseRef(database, `/Artists/${user.uid}/lastPageVisited`);

        try {
          // Set the user online status to "true"
          await set(userStatusRef, true);

          // Use `onDisconnect` to set the status to "false" when the user disconnects
          onDisconnect(userStatusRef).set(false);

          // Save the last visited page in Firebase
          if (location.pathname) {
            await set(lastPageRef, location.pathname); // Save the current route in Firebase
          }

          const lastSessionSnapshot = await get(lastSessionTimestampRef);
          const lastSessionTimestamp = lastSessionSnapshot.exists() ? lastSessionSnapshot.val() : 0;
          const currentTimestamp = Date.now();

          if (currentTimestamp - lastSessionTimestamp >= FIVE_MINUTES) {
            const sessionCounterSnapshot = await get(sessionCounterRef);
            const sessionCount = sessionCounterSnapshot.exists() ? sessionCounterSnapshot.val() : 0;
            await set(sessionCounterRef, sessionCount + 1);

            await set(lastSessionTimestampRef, currentTimestamp);

            const newSessionKey = uuidv4();
            await update(sessionTimestampsRef, {
              [newSessionKey]: currentTimestamp,
            });

            console.log("New session added:", currentTimestamp);
          } else {
            console.log("Less than 5 minutes since the last session. No new session counted.");
          }
        } catch (error) {
          console.error("Error updating online status and session data:", error);
        }
      }
    };

    updateOnlineStatusAndSession();

    const unsubscribe = auth.onAuthStateChanged(() => {
      updateOnlineStatusAndSession();
    });

    const intervalId = setInterval(updateOnlineStatusAndSession, 30 * 1000);

    return () => {
      clearInterval(intervalId);
      unsubscribe();
    };
  }, [location.pathname]); // Only call when path changes
};

export default useUserOnlineStatus;

import React from 'react';
import './ArtistAnalysis.css'; // Make sure to import the new CSS file

function ArtistAnalysis({ artists }) {
  const artistStats = {
    uploadedNoAwardOrNomination: 0,
    uploadedAndNominated: 0,
    uploadedAndAwarded: 0,
    totalSuperusers: 0,
    totalArtists: artists.length,
    incompleteSettings: 0,
    submittedArtists: 0, // Count of artists who submitted a song
    completedSettings: 0, // Count of artists who completed their settings
  };

  const artistPageVisits = {}; // Object to store page visit frequencies

  // Loop through each artist
  artists.forEach((artist) => {
    // Check if the artist has submitted a song (hasSubmitted is true)
    if (artist.hasSubmitted === 'Ja') {  // 'Ja' indicates that the artist has submitted
      artistStats.submittedArtists += 1;

      // Check awards/nominations for submitted artists
      if (artist.numberAwards > 0) {
        artistStats.uploadedAndAwarded += 1;
      } else if (artist.numberNominated > 0) {
        artistStats.uploadedAndNominated += 1;
      } else {
        artistStats.uploadedNoAwardOrNomination += 1;
      }
    }

    // Check if the artist is a superuser (10 or more sessions)
    if (artist.sessionCounter >= 10) {
      artistStats.totalSuperusers += 1;
    }

    // Check if the artist has completed their settings
    if (artist.hasSettings) {
      artistStats.completedSettings += 1;
    } else {
      artistStats.incompleteSettings += 1;
    }

    // Collect last page visited if it exists
    if (artist.lastPageVisited) {
      if (artistPageVisits[artist.lastPageVisited]) {
        artistPageVisits[artist.lastPageVisited] += 1;
      } else {
        artistPageVisits[artist.lastPageVisited] = 1;
      }
    }
  });

  // Calculate the success rate for artists who submitted a song
  const successRate = artistStats.totalArtists > 0 
    ? ((artistStats.submittedArtists / artistStats.totalArtists) * 100).toFixed(2) 
    : 0;

  // Calculate the completed settings rate
  const completedSettingsRate = artistStats.totalArtists > 0 
    ? ((artistStats.completedSettings / artistStats.totalArtists) * 100).toFixed(2) 
    : 0;

  // Sort pages by frequency
  const sortedPageVisits = Object.entries(artistPageVisits).sort((a, b) => b[1] - a[1]);

  // Get the top 20 artists by session count
  const topArtistsBySessions = [...artists]
    .sort((a, b) => b.sessionCounter - a.sessionCounter) // Sort artists by session count in descending order
    .slice(0, 20); // Get only the top 20

  // Function to format timestamp to readable date
  const formatDate = (timestamp) => {
    return timestamp ? new Date(timestamp).toLocaleDateString() : 'N/A';
  };

  return (
    <div className="analysis-artist-dashboard">
      <h2>Artist Analysis</h2>

      {/* Artist stats */}
      <div className="analysis-cards-container">
        <div className="analysis-card">
          <div className="analysis-number">{artistStats.uploadedNoAwardOrNomination}</div>
          <div className="analysis-description">No Awards/Nominations</div>
        </div>
        <div className="analysis-card analysis-blue">
          <div className="analysis-number">{artistStats.uploadedAndNominated}</div>
          <div className="analysis-description">Nominated Artists</div>
        </div>
        <div className="analysis-card analysis-red">
          <div className="analysis-number">{artistStats.uploadedAndAwarded}</div>
          <div className="analysis-description">Awarded Artists</div>
        </div>
      </div>

      {/* New statistics */}
      <div className="analysis-cards-container">
        <div className="analysis-card">
          <div className="analysis-number">{artistStats.totalSuperusers}</div>
          <div className="analysis-description">Total Superusers</div>
        </div>
        <div className="analysis-card analysis-blue">
          <div className="analysis-number">{artistStats.totalArtists}</div>
          <div className="analysis-description">Total Artists</div>
        </div>
        <div className="analysis-card analysis-red">
          <div className="analysis-number">{artistStats.incompleteSettings}</div>
          <div className="analysis-description">Incomplete Settings</div>
        </div>
        <div className="analysis-card analysis-success-rate"> {/* Success Rate Box */}
          <div className="analysis-number">{successRate}%</div>
          <div className="analysis-description">Success Rate (Submitted)</div>
        </div>
        <div className="analysis-card analysis-completed-settings"> {/* Completed Settings Box */}
          <div className="analysis-number">{completedSettingsRate}%</div>
          <div className="analysis-description">Completed Settings Rate</div>
        </div>
      </div>

      {/* Scrollable container for page visits */}
      <div className="artist-page-visits-container">
        <h3>Last Page Visited by Artists</h3>
        <div className="artist-page-visits-list">
          {sortedPageVisits.length > 0 ? (
            sortedPageVisits.map(([page, frequency]) => (
              <div key={page} className="artist-page-visits-item">
                <span className="artist-page-name">{page}</span>
                <span className="artist-page-frequency">{frequency} visit(s)</span>
              </div>
            ))
          ) : (
            <p>No page visit data available.</p>
          )}
        </div>
      </div>

      {/* Scrollable container for top 20 artists by session count */}
      <div className="analysis-top-artists-sessions-container">
        <h3>Top 20 Artists by Session Count</h3>
        <div className="analysis-top-artists-sessions-list">
          {topArtistsBySessions.map((artist) => {
            const sessionTimestamps = Object.values(artist.sessionTimestamps || {});
            const firstSession = sessionTimestamps.length ? Math.min(...sessionTimestamps) : null;
            const lastSession = artist.lastSessionTimestamp || null;

            return (
              <div key={artist.id} className="analysis-top-artists-sessions-item">
                <span className="analysis-artist-name">{artist.artistName || 'Unknown'}</span>
                <span className="analysis-session-count">{artist.sessionCounter} sessions</span>
                <span className="analysis-first-session">First: {formatDate(firstSession)}</span>
                <span className="analysis-last-session">Last: {formatDate(lastSession)}</span>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}

export default ArtistAnalysis;

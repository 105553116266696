import React, { useState, useEffect } from 'react';
import { ref as databaseRef, push, set, update, get, query, orderByChild, equalTo } from 'firebase/database';
import { database, auth } from '../../firebaseConfig';
import './SuperChat.css'; // Import der CSS-Datei

const SuperChat = ({ chatId, receiverId }) => {
    const [modalShow, setModalShow] = useState(false);
    const [gigs, setGigs] = useState([]);
    const [selectedGigId, setSelectedGigId] = useState('');
    const [isReviewRequestSent, setIsReviewRequestSent] = useState(false);

    useEffect(() => {
        const fetchGigs = async () => {
            const currentUser = auth.currentUser;
            if (!currentUser) {
                console.error('No user logged in');
                return;
            }

            // Query to fetch pages that belong to the current user and are live
            const pagesRef = query(
                databaseRef(database, 'Pages'),
                orderByChild('artistId'),
                equalTo(currentUser.uid)
            );

            const snapshot = await get(pagesRef);
            const gigsArray = [];

            snapshot.forEach(childSnapshot => {
                const gigData = childSnapshot.val();
                if (gigData.pageData && gigData.pageData.pageIsLive) {
                    gigsArray.push({
                        id: childSnapshot.key,
                        name: gigData.pageData.service || 'Untitled Gig',
                    });
                }
            });

            setGigs(gigsArray);
            setSelectedGigId(gigsArray.length > 0 ? gigsArray[0].id : '');
        };

        fetchGigs();
    }, []);

    const toggleModal = () => setModalShow(!modalShow);

    const checkIfReviewExists = async (gigId) => {
        const reviewsRef = query(
            databaseRef(database, `Pages/${gigId}/pageData/reviews`),
            orderByChild('reviewWriter'),
            equalTo(receiverId)
        );

        const snapshot = await get(reviewsRef);

        return snapshot.exists(); // Rückgabe true, wenn eine Bewertung existiert
    };

    const handleSendReviewRequest = async () => {
        const currentUser = auth.currentUser;
        if (!currentUser) {
            console.error('No user logged in');
            return;
        }
    
        if (!selectedGigId) {
            console.error('No gig selected');
            return;
        }
    
        if (!receiverId) {
            console.error('Receiver ID is undefined');
            return;
        }
    
        // Überprüfen, ob bereits eine Bewertung für diesen Gig von diesem Nutzer existiert
        const reviewExists = await checkIfReviewExists(selectedGigId);
        if (reviewExists) {
            console.error('This user has already left a review for this gig.');
            setIsReviewRequestSent(true);
            return;
        }
    
        const messagesRef = databaseRef(database, `Chats/${chatId}/messages`);
        const newMessageRef = push(messagesRef);
        const timestamp = new Date().toISOString();
    
        await set(newMessageRef, {
            message: "Do you want to give me a review?",
            imageUrl: '',  // Keine Bild-URL für diese Nachricht
            senderId: currentUser.uid,
            receiverId: receiverId, // Receiver-ID hinzufügen
            type: "AskReview",
            gigId: selectedGigId,  // Gig-ID in der Nachricht speichern
            timestamp,
            readStatus: false  // Nachricht als ungelesen markieren
        });
    
        // Update des letzten Nachrichten-Zeitstempels im Chat-Objekt
        const chatRef = databaseRef(database, `Chats/${chatId}`);
        await update(chatRef, {
            lastMessageAt: timestamp
        });
    
        setIsReviewRequestSent(false);
        toggleModal(); // Modal schließen, nachdem die Nachricht gesendet wurde
    };
    
    return (
        <div>
            <button className="superchat-open-button" onClick={toggleModal}>
                <img src="../../img/plus.svg" alt="plus" />
            </button>
            {modalShow && (
                <div className="superchat-modal-backdrop">
                    <div className="superchat-modal-content">
                        <div className="superchat-modal-header">
                            <h5 className="superchat-modal-title">Super<span className="supersonic">Chat</span></h5>
                            <button className="superchat-close-button" onClick={toggleModal}>&times;</button>
                        </div>
                        <div className="superchat-modal-body">
                            {isReviewRequestSent ? (
                                <p>This user has already left a review for this gig.</p>
                            ) : gigs.length > 0 ? (
                                <div className="request-review-top">
                                 <h2>Ask for Gig Review</h2>
                                <div className="superchat-gig-selector">
                                  <div>
                                    <label htmlFor="gig-select">Select the gig:</label>
                                    <select
                                        id="gig-select"
                                        value={selectedGigId}
                                        onChange={(e) => setSelectedGigId(e.target.value)}
                                        className="superchat-gig-select"
                                    >
                                        {gigs.map(gig => (
                                            <option key={gig.id} value={gig.id}>
                                                {gig.name}
                                            </option>
                                        ))}
                                    </select>
                                   
                                    </div>
                                    <button onClick={handleSendReviewRequest} className="review-request-button">
                                        Request a Review
                                        <img src="./img/plus.svg" alt="" />
                                    </button>
                                    </div>

                                </div>
                            ) : (
                                <p>No live gigs available for review.</p>
                            )}
                        </div>
 
                    </div>
                </div>
            )}
        </div>
    );
};

export default SuperChat;

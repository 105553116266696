import React, { useEffect, useState, useRef } from 'react';
import { getAuth } from 'firebase/auth';
import { ref as databaseRef, set } from 'firebase/database';
import { database } from '../firebaseConfig';
import { v4 as uuidv4 } from 'uuid';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { loadStripe } from '@stripe/stripe-js';
import './ViralBuyModal.css';

// Stripe config
const stripePromise = loadStripe('your-stripe-public-key');

// Features to display
const features = [
  { imgSrc: '../../img/award_1.webp', imgSrc2: '../../img/check_4.webp', label: 'Win Awards', text: 'Submit unlimited songs and win your first AAA Award.', worth: '29' },
  { imgSrc: '../../img/grant.webp', imgSrc2: '../../img/check_4.webp', label: 'Top 1% Network', text: 'Connect with the top 1% of artists on AAA and fast-track your career.', worth: '39' },
  { imgSrc: '../../img/tt_spy.webp', imgSrc2: '../../img/check_4.webp', label: 'TikTok Spy (BETA)', text: 'Let our AI find the hottest TikTok reels to help you grow your music fanbase.', worth: '39' },
  { imgSrc: '../../img/yarak.webp', imgSrc2: '../../img/check_4.webp', label: 'Marketplace Access', text: 'Create up to 10 gigs on our marketplace and start earning money today.', worth: '19' },
  { imgSrc: '../../img/glare.webp', imgSrc2: '../../img/check_4.webp', label: 'AI Connect', text: 'Connect with thousands of artists through AI-powered messaging.', worth: '4' },
  { imgSrc: '../../img/days_2.webp', imgSrc2: '../../img/check_4.webp', label: '30-Day Money-Back Guarantee', text: 'Not satisfied? Get a full refund within 30 days—no questions asked.', worth: '' },
];

const numVisible = 3;

const ViralBuyModal = ({ isOpen, onClose }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isTransitioning, setIsTransitioning] = useState(false);
  const [loading, setLoading] = useState(false);

  const carouselRef = useRef(null);
  const startX = useRef(0);
  const scrollLeft = useRef(0);
  const isDragging = useRef(false);

  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }

    return () => {
      document.body.style.overflow = 'auto';
    };
  }, [isOpen]);

  const handleNext = () => {
    if (isTransitioning) return;
    setIsTransitioning(true);
    setTimeout(() => {
      setIsTransitioning(false);
      setCurrentIndex((prevIndex) => (prevIndex >= features.length - numVisible ? 0 : prevIndex + 1));
    }, 50);
  };

  const handlePrev = () => {
    if (isTransitioning) return;
    setIsTransitioning(true);
    setTimeout(() => {
      setIsTransitioning(false);
      setCurrentIndex((prevIndex) => (prevIndex === 0 ? features.length - numVisible : prevIndex - 1));
    }, 50);
  };

  const handlePointerDown = (e) => {
    isDragging.current = true;
    startX.current = e.pageX || e.touches[0].pageX;
    scrollLeft.current = carouselRef.current.scrollLeft;
  };

  const handlePointerMove = (e) => {
    if (!isDragging.current) return;
    const x = e.pageX || e.touches[0].pageX;
    const walk = (x - startX.current) * 1.5; // Adjust scroll speed
    carouselRef.current.scrollLeft = scrollLeft.current - walk;
  };

  const handlePointerUp = () => {
    isDragging.current = false;
  };

  // Store analytics data in Firebase
  const saveAnalyticsEvent = async () => {
    const auth = getAuth();
    const user = auth.currentUser;

    if (user) {
      const randomId = uuidv4();
      const analyticsRef = databaseRef(database, `Analytics/ViralBuyModal/${randomId}`);
      const timestamp = Date.now();

      try {
        await set(analyticsRef, {
          userId: user.uid,
          timestamp,
        });
        console.log('Analytics event saved successfully.');
      } catch (error) {
        console.error('Error saving analytics event:', error);
      }
    }
  };

  const handleCheckout = async () => {
    setLoading(true);
    const auth = getAuth();
    const user = auth.currentUser;

    if (user) {
      const functions = getFunctions();
      const createCheckoutSession = httpsCallable(functions, 'createCheckoutSession');

      try {
        await saveAnalyticsEvent();
        const { data } = await createCheckoutSession({ userId: user.uid, priceId: 'price_1PrlR5Hh3Mj0zlrOvLEs5o0a' });
        const stripe = await stripePromise;
        const { error } = await stripe.redirectToCheckout({ sessionId: data.sessionId });

        if (error) {
          console.error('Stripe redirect error:', error.message);
          setLoading(false);
        }
      } catch (error) {
        console.error('Checkout session error:', error.message);
        setLoading(false);
      }
    } else {
      console.error('User is not logged in');
      setLoading(false);
    }
  };

  // Animation class toggle
  const [showClass, setShowClass] = useState(false);
  useEffect(() => {
    if (isOpen) {
      setTimeout(() => setShowClass(true), 10);
    } else {
      setShowClass(false);
    }
  }, [isOpen]);

  if (!isOpen) return null;

  return (
    <div className={`viral-buy-modal-overlay ${isOpen ? 'show' : ''}`}>
      <div className={`viral-buy-modal-container ${showClass ? 'show' : ''}`}>
        <button className="viral-buy-modal-close-button" onClick={onClose}>✕</button>

        <div className="viral-buy-modal-vip-header">
          <img src="../../img/badge.webp" alt="VIP Badge" className="viral-buy-modal-vip-icon" />
          <h1 className="viral-buy-modal-title">Join the VIPs</h1>
          <p style={{ marginTop: '20px', marginBottom: '0px', color: 'grey' }}>Our VIP Membership includes the following packages:</p>
        </div>

        <div className="viral-buy-modal-features-carousel">
          <button className="carousel-button prev-button" onClick={handlePrev}>
            <img src="../../img/down.svg" alt="Previous" />
          </button>

          <div
            className="carousel-feature-container"
            ref={carouselRef}
            onMouseDown={handlePointerDown}
            onMouseMove={handlePointerMove}
            onMouseUp={handlePointerUp}
            onTouchStart={handlePointerDown}
            onTouchMove={handlePointerMove}
            onTouchEnd={handlePointerUp}
          >
            <div
              className="grid-feature-wrapper"
              style={{
                transform: `translateX(-${currentIndex * 202}px)`,
                transition: isTransitioning ? 'none' : 'transform 0.3s ease',
              }}
            >
              {features.map((feature, index) => (
                <div className="viral-buy-modal-feature" key={index}>
                  <img src={feature.imgSrc} alt={feature.label} />
                  <p>{feature.label}</p>
                  <img src={feature.imgSrc2} className="viral-buy-circle-check" alt={feature.text} />
                  {feature.worth && (
                    <div className="viral-buy-circle-check-2">
                      <p>
                        <span>${feature.worth}</span>
                        <br />month
                      </p>
                    </div>
                  )}
                  <p className="viral-boy-modal-text">{feature.text}</p>
                </div>
              ))}
            </div>
          </div>

          <button className="carousel-button nextolo-button" onClick={handleNext}>
            <img src="../../img/down.svg" alt="Next" />
          </button>
        </div>
        <div className="viral-buy-mobile-price">
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <span className="viral-buy-modal-price-2"><p>$130/month</p> <h6>-94%</h6></span>
              <span style={{margin: '0px', textAlign: 'center', fontSize: '22px'}} className="viral-buy-modal-price">$79/Year</span>
            </div>
          </div>
        <div className="viral-buy-modal-price-button" onClick={handleCheckout} style={{ cursor: 'pointer' }}>
          <div className="viral-buy-modal-button-texts" style={{ display: 'flex', flexDirection: 'column' }}>
            <h3>All inclusive special price</h3>
            <p className="viral-buy-modal-start-text">VIP Members Bundle</p>
          </div>
          
          <div>
              
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <span className="viral-buy-modal-price-2"><p>$130/month</p> <h6>-94%</h6></span>
              <span className="viral-buy-modal-price">$79/Year</span>
            </div>
            <button className="viral-buy-modal-arrow-button" disabled={loading}>
              {loading ? (
                <div className="loadinge"></div>
              ) : (
                <img src="../../img/down.svg" alt="Arrow" />
              )}
            </button>
          </div>
        </div>

        <div className="viral-botttom-ckeckout-container">
          <img className="stripe-secure-checkout" src="../../img/stripe_secure_2.webp" />
          <img className="stripe-secure-checkout-2" src="../../img/mbg.webp" />
        </div>
      </div>
    </div>
  );
};

export default ViralBuyModal;

import React, { useEffect, useState } from 'react';
import { ref as databaseRef, onValue, remove } from 'firebase/database';
import { database } from "../firebaseConfig";
import './ListSongs.css';
import AddPublicationModal from './AddPublicationModal';
import AddEditorModal from './AddEditorModal';
import PlaylistStatus from './PlaylistStatus';
import ChartlistAndAwardManager from './ChartlistAndAwardManager'; // Importiere die neue Komponente
import checkIcon from '../img/check.svg'; // Verwende das Check-Icon
import reviewIcon from '../img/right.svg'; // Verwende das Review-Icon

function ListSongs() {
  const [songs, setSongs] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false); // State für das Chartlist & Award Modal
  const [currentSongId, setCurrentSongId] = useState(null);
  const [isPublicationModalOpen, setIsPublicationModalOpen] = useState(false);
  const [selectedSongId, setSelectedSongId] = useState(null);
  const [isEditorModalOpen, setIsEditorModalOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(30); // Set to 30 items per page

  useEffect(() => {
    const songsRef = databaseRef(database, 'Songs');
    onValue(songsRef, (snapshot) => {
      const songsData = snapshot.val();
      let songsList = songsData ? Object.keys(songsData).map(key => ({
        id: key,
        ...songsData[key],
      })) : [];
  
      songsList.sort((a, b) => new Date(b.releaseTimestamp) - new Date(a.releaseTimestamp));
  
      setSongs(songsList);
    });
  }, []);

  const indexOfLastSong = currentPage * itemsPerPage;
  const indexOfFirstSong = indexOfLastSong - itemsPerPage;
  const currentSongs = songs.slice(indexOfFirstSong, indexOfLastSong);

  const paginate = pageNumber => setCurrentPage(pageNumber);

  const pageNumbers = [];
  for (let i = 1; i <= Math.ceil(songs.length / itemsPerPage); i++) {
    pageNumbers.push(i);
  }

  const openEditorModal = (songId) => {
    setSelectedSongId(songId);
    setIsEditorModalOpen(true);
  };
  
  const closeEditorModal = () => {
    setIsEditorModalOpen(false);
  };

  const openModal = (songId) => {
    setCurrentSongId(songId);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const handleOverlayClick = (event) => {
    if (event.target.classList.contains('caam-modal-overlay')) {
      closeModal();
    }
  };

  const openPublicationModal = (songId) => {
    setSelectedSongId(songId);
    setIsPublicationModalOpen(true);
  };

  const closePublicationModal = () => {
    setIsPublicationModalOpen(false);
  };

  const deleteSong = (songId) => {
    const isConfirmed = window.confirm("Sind Sie sicher, dass Sie diesen Song löschen möchten?");
    if (isConfirmed) {
      const songRef = databaseRef(database, `Songs/${songId}`);
      remove(songRef)
        .then(() => {
          console.log(`Song ${songId} erfolgreich gelöscht.`);
        })
        .catch((error) => {
          console.error("Fehler beim Löschen des Songs: ", error);
        });
    }
  };

  return (
    <div className="list-songs-container">
      <h1>Songliste</h1>
      <p>Gesamtanzahl der Einträge: {songs.length}</p>
      <div className="table-scroll-container">
        <ul className="pagination">
          {pageNumbers.map(number => (
            <li key={number} className="page-item">
              <button onClick={() => paginate(number)} className="page-link">
                {number}
              </button>
            </li>
          ))}
        </ul>
        <AddPublicationModal
          isOpen={isPublicationModalOpen}
          onClose={closePublicationModal}
          songId={selectedSongId}
        />
        <AddEditorModal
          isOpen={isEditorModalOpen}
          onClose={closeEditorModal}
          songId={selectedSongId}
        />
        <table className="list-songs-table">
          <thead>
            <tr>
              <th className="list-songs-name-field">Name</th>
              <th>Künstler</th>
              <th>Genre</th>
              <th>Timestamp</th>
              <th>Chartlist</th>
              <th>Manage Chartlist & Award</th>
              <th>Song-Link</th>
              <th>Add Magazine</th>
              <th>Add Editor Quote</th>
              <th>Löschen</th>
            </tr>
          </thead>
          <tbody>
            {currentSongs.map((song) => (
              <tr key={song.id}>
                <td>{song.name}</td>
                <td>{song.artist}</td>
                <td>{song.genre}</td>
                <td>{new Date(song.releaseTimestamp).toLocaleDateString('de-DE')}</td>
                <td>
                  <PlaylistStatus chartlists={song.chartlists || {}} />
                </td>
                <td>
                  {/* Überprüfen, ob der Song bereits in der Chartlist ist */}
                  {song.chartlists && Object.keys(song.chartlists).length > 0 ? (
                    <button  onClick={() => openModal(song.id)} className="manage-chartlist-button-review">
                     Reviewed <img src={checkIcon} alt="Reviewed Icon"  />
                    </button>
                  ) : (
                    <button onClick={() => openModal(song.id)} className="manage-chartlist-button">
                    Review Song  <img src={reviewIcon} alt="Review Icon"  /> 
                    </button>
                  )}
                  
                  {isModalOpen && currentSongId === song.id && (
                    <div className="caam-modal-overlay" onClick={handleOverlayClick}>
                      <div className="caam-modal-content" onClick={(e) => e.stopPropagation()}>
                        <ChartlistAndAwardManager
                          songId={song.id}
                          chartlists={song.chartlists}
                          isNominated={song.isNominated}
                          hasAward={song.hasAward}
                          closeModal={closeModal} 
                        />
                      </div>
                    </div>
                  )}
                </td>
                <td>
                  <a href={`/song/${song.id}`} target="_blank" rel="noopener noreferrer">Song ansehen</a>
                </td>
                <td>
                  <button id="add-table-button-2" onClick={() => openPublicationModal(song.id)}>Magazines</button>
                </td>
                <td>
                  <button id="add-table-button-3" onClick={() => openEditorModal(song.id)}>Editor Quote</button>
                </td>
                <td>
                  <button id="delete-table-button" onClick={() => deleteSong(song.id)}>Löschen</button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default ListSongs;

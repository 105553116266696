import { initializeApp } from "firebase/app";
import { ref, set, get, update } from 'firebase/database';
import { getDatabase } from 'firebase/database';
import { getStorage } from 'firebase/storage';
import { getAuth, onAuthStateChanged, createUserWithEmailAndPassword } from 'firebase/auth';

// Your web app's Firebase configuration
const firebaseConfig = {
    apiKey: process.env.REACT_APP_API_KEY,
    authDomain: process.env.REACT_APP_AUTH_DOMAIN,
    projectId: process.env.REACT_APP_PROJECT_ID,
    storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_APP_ID,
    databaseURL: process.env.REACT_APP_DATABASE_URL
  };
  
  
// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const database = getDatabase(app);
const storage = getStorage(app);

function getCurrentTimestamp() {
    return new Date().toISOString();
}

export function addUserEntry(userId) {
    const userRef = ref(database, `Artists/${userId}`);
    return update(userRef, { hasSettings: false });
}

// Hier fügen wir die neuen Parameter der Funktion hinzu
export function registerUser(userId, email, displayName = '') {
    const userRef = ref(database, `Artists/${userId}`);
    const now = getCurrentTimestamp();
    
    return set(userRef, {
        email: email,
        name: displayName,
        signupTimestamp: now,
        hasSettings: false,
        isPremium: false,
        songCredits: 1, 
        sessionCount: 1,
        lastLogin: now,
        lastAccess: now,
        numberAwards: 0,
        numberNominated: 0,
    });
}


export function completeUserRegistration(userId, email) {
    const userRef = ref(database, `Artists/${userId}`);
    const now = getCurrentTimestamp();
    
    // Vollständige Daten hinzufügen, nachdem die E-Mail verifiziert wurde
    const fullUserData = {
        email: email,
      isPremium: false,
      songCredits: 1,
      sessionCount: 1,
      lastLogin: now,
      lastAccess: now,
      numberAwards: 0,
      numberNominated: 0,
      // 'name' könnte leer sein oder vom Nutzer bei der Registrierung bereitgestellt worden sein
    };
  
    return update(userRef, fullUserData);
  }




function registerWithEmailPassword(email, password, displayName, artistName, country) {
    const auth = getAuth();
    createUserWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        // Der Benutzer wurde erstellt
        const user = userCredential.user;
        // Jetzt speichern wir die zusätzlichen Informationen in der Datenbank
        registerUser(user.uid, email, displayName, artistName, country)
          .then(() => console.log('Benutzerdaten gespeichert.'))
          .catch(error => console.error('Fehler beim Speichern der Benutzerdaten:', error));
      })
      .catch((error) => {
        console.error('Fehler bei der Registrierung:', error);
      });
  }

  onAuthStateChanged(auth, user => {
    if (user) {
        const userId = user.uid;
        
        get(ref(database, `Artists/${userId}`)).then(snapshot => {
            if (!snapshot.exists()) {
                // Unterscheiden, ob der Nutzer über Google angemeldet ist
                if (user.providerData[0].providerId === "google.com") {
                    // Nutzer hat sich mit Google angemeldet
                    registerUser(userId, user.email, user.displayName);
                } else {
                    // Nutzer hat sich mit E-Mail und Passwort angemeldet
                    registerUser(userId, user.email);
                }
            } else {
                // Hier könntest du Logik hinzufügen, um bestimmte Daten bei jedem Login zu aktualisieren
            }
        });
    }
});

export { app, database, storage, auth };

import React, { useState, useEffect, useRef } from 'react';
import './ChatWindow.css';
import { ref as databaseRef, get, onValue, update, query, orderByChild, limitToLast } from 'firebase/database';
import { database, auth } from '../../firebaseConfig';
import ChatInput from './ChatInput';
import DummyChatInput from './DummyChatInput';
import CollabStartComponent from './CollabStartComponent';
import ReviewChat from './ReviewChat';
import ReviewChatSent from './ReviewChatSent'; // Import der neuen ReviewChatSent-Komponente

const INITIAL_PAGE_SIZE = 30;

const ChatWindow = ({ chatId }) => {
    const [messages, setMessages] = useState([]);
    const [currentUserImage, setCurrentUserImage] = useState('https://example.com/default-user-image.jpg');
    const [partnerImage, setPartnerImage] = useState('https://example.com/default-profile.jpg');
    const [loading, setLoading] = useState(false);
    const [pageSize, setPageSize] = useState(INITIAL_PAGE_SIZE);
    const messagesEndRef = useRef();
    const initialLoad = useRef(true);
    const [initiatorId, setInitiatorId] = useState(null);
    const [shouldShowDummyInput, setShouldShowDummyInput] = useState(false);

    useEffect(() => {
        const currentUser = auth.currentUser;
        const messagesRef = query(databaseRef(database, `Chats/${chatId}/messages`), orderByChild('timestamp'), limitToLast(pageSize));

        const fetchChatAndUserInfo = async () => {
            const chatRef = databaseRef(database, `Chats/${chatId}`);
            const chatSnapshot = await get(chatRef);
            if (chatSnapshot.exists()) {
                const chatData = chatSnapshot.val();
                const partnerId = chatData.initiatorId === currentUser.uid ? chatData.recipientId : chatData.initiatorId;
                setInitiatorId(chatData.initiatorId);
                const currentUserRef = databaseRef(database, `Artists/${currentUser.uid}/artistImageUrl`);
                const partnerRef = databaseRef(database, `Artists/${partnerId}/artistImageUrl`);
                const [currentUserSnapshot, partnerSnapshot] = await Promise.all([
                    get(currentUserRef),
                    get(partnerRef)
                ]);
                setCurrentUserImage(currentUserSnapshot.exists() ? currentUserSnapshot.val() : 'https://example.com/default-user-image.jpg');
                setPartnerImage(partnerSnapshot.exists() ? partnerSnapshot.val() : 'https://example.com/default-profile.jpg');
            }
        };

        fetchChatAndUserInfo();

        const unsubscribe = onValue(messagesRef, (snapshot) => {
            if (snapshot.exists()) {
                const newMessages = [];
                snapshot.forEach(childSnapshot => {
                    newMessages.push({ id: childSnapshot.key, ...childSnapshot.val() });
                });
                setMessages(newMessages);
                markMessagesAsRead(newMessages);
                scrollToBottom();

                const isCollabStartMessage = newMessages.some(message => message.type === 'collab-start');
                const hasRecipientReplied = newMessages.some(message => message.senderId !== auth.currentUser.uid);

                if (isCollabStartMessage && !hasRecipientReplied) {
                    setShouldShowDummyInput(true);
                } else {
                    setShouldShowDummyInput(false);
                }
            }
        });

        return () => {
            unsubscribe();
            initialLoad.current = true;
        };
    }, [chatId, pageSize]);

    useEffect(() => {
        if (initialLoad.current && messages.length > 0) {
            scrollToBottom(); 
            initialLoad.current = false;
        }
    }, [messages]);

    const markMessagesAsRead = (messages) => {
        messages.forEach(message => {
            if (message.senderId !== auth.currentUser.uid && !message.readStatus) {
                const msgRef = databaseRef(database, `Chats/${chatId}/messages/${message.id}`);
                update(msgRef, { readStatus: true });
            }
        });
    };

    const scrollToBottom = () => {
        const scrollContainer = document.querySelector('.messages-list');
        if (scrollContainer) {
            const start = scrollContainer.scrollTop;
            const end = scrollContainer.scrollHeight;
            const distance = end - start;
            const duration = 200; 
            const startTime = performance.now();
    
            const easeInOutQuad = (t) => t < 0.5 ? 2 * t * t : -1 + (4 - 2 * t) * t;
    
            const scrollStep = (timestamp) => {
                const currentTime = timestamp - startTime;
                const scrollProgress = easeInOutQuad(Math.min(1, currentTime / duration));
                scrollContainer.scrollTop = start + distance * scrollProgress;
    
                if (currentTime < duration) {
                    requestAnimationFrame(scrollStep);
                }
            };
    
            requestAnimationFrame(scrollStep);
        }
    };

    const loadMoreMessages = () => {
        if (!loading) {
            setLoading(true);
            setPageSize(prevPageSize => prevPageSize + INITIAL_PAGE_SIZE);  
            setLoading(false);
        }
    };

    return (
        <div className="chat-window">
            <div className="messages-list">
                {messages.length > INITIAL_PAGE_SIZE && (
                    <button onClick={loadMoreMessages} disabled={loading} className="load-more-btn">Load More Messages</button>
                )}
                {messages.map((message) => {
                    const isCurrentUserMessage = message.senderId === auth.currentUser.uid;
                    const lastMessage = messages[messages.length - 1];
                    const isLastMessageFromCurrentUser = lastMessage && lastMessage.senderId === auth.currentUser.uid;
                    const showReadStatus = isLastMessageFromCurrentUser && lastMessage.id === message.id;

                    return (
                        <div key={message.id} className={`message ${isCurrentUserMessage ? 'right' : 'left'}`}>
                            <div className="bubble">
                                {message.type === 'AskReview' ? (
                                    <ReviewChat 
                                        currentUser={auth.currentUser} 
                                        senderId={message.senderId} 
                                        gigId={message.gigId}  
                                        recipientId={message.receiverId} 
                                        chatId={chatId}
                                        messageId={message.id}  // Hier wird die messageId übergeben
                                    />
                                ) : message.type === 'ReviewSent' ? (
                                    <ReviewChatSent currentUser={auth.currentUser} senderId={message.senderId} />
                                ) : message.type === 'collab-start' && message.containers ? (
                                    <CollabStartComponent     message={message.message} 
                                    containers={message.containers} initiatorId={initiatorId} />
                                ) : (
                                    <p style={{ whiteSpace: 'pre-wrap' }}>{message.message.trimEnd()}</p>
                                    )}
                                {message.imageUrl && <img src={message.imageUrl} alt="Message" />}
                                {showReadStatus && (
                                    <div className="read-status">
                                        {message.readStatus ? 'Read' : 'Sent'}
                                    </div>
                                )}
                            </div>
                            {!isCurrentUserMessage && <img src={partnerImage} alt="Profile" className="chat-profile-image left" />}
                            {isCurrentUserMessage && <img src={currentUserImage} alt="Profile" className="chat-profile-image right" />}
                        </div>
                    );
                })}
                <div ref={messagesEndRef} /> 
            </div>
            {shouldShowDummyInput ? <DummyChatInput /> : <ChatInput chatId={chatId} />}
        </div>
    );
};

export default ChatWindow;

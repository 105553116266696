import React, { useState, useRef, useEffect } from 'react';
import { auth, database, storage } from '../firebaseConfig';
import { ref as storageRef, uploadBytes, getDownloadURL } from 'firebase/storage';
import SubGenreList from '../Utility/SubGenreList';
import './SongStepper.css';
import playIcon from '../img/play.svg';
import pauseIcon from '../img/pause.svg';
import MoodList from '../Utility/MoodList';
import EquipmentList from '../Utility/EquipmentList';
import { initializeApp } from 'firebase/app';
import { getDatabase, ref as databaseRef, push, set, get, child } from 'firebase/database';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { Link } from 'react-router-dom';
import { compressImage } from '../Utility/ImageCompressor'; // Stellen Sie sicher, dass der Pfad korrekt ist
import { useNavigate } from 'react-router-dom';
import GenerativeOrbAnimation from '../Utility/GenerativeOrbAnimation';
import CollaboratorList from '../Utility/CollaboratorList';
import { motion } from 'framer-motion';
import CompletedStep5 from './CompletedStep5';
import GenerativeOrbAnimation3 from '../Utility/GenerativeOrbAnimation3';
import GenerativeOrbAnimation4 from '../Utility/GenerativeOrbAnimation4';
import GenerativeOrbAnimation5 from '../Utility/GenerativeOrbAnimation5';
import ShowStepperSteps from './ShowStepperSteps';
import SlideStepperSteps from './SlideStepperSteps';
import BuyProModal from '../Pages/BuyProModal';



function SongStepper() {
  const [currentStep, setCurrentStep] = useState(1);
  const [songData, setSongData] = useState({
    songName: '',
    genre: '',
    mood: [],
    songUrl: '',
    coverUrl: '',
    spotifyLink: '',
    youtubeLink: '', // Feld für YouTube-Link hinzugefügt    // Neues Feld für den Spotify-Link
  });

  const [uploading, setUploading] = useState(false);
  const [songPreview, setSongPreview] = useState(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [playbackProgress, setPlaybackProgress] = useState(0);
  const audioRef = useRef(new Audio());
  const [fileName, setFileName] = useState(''); // Zustand für den Dateinamen
  const [coverUrl, setCoverUrl] = useState('');
  const [coverUploading, setCoverUploading] = useState(false);
  const [equipment, setEquipment] = useState([]);
  const [songCredits, setSongCredits] = useState(0);
  const [isPremium, setIsPremium] = useState(false); // Premium-Status hinzufügen
  const navigate = useNavigate();
  const moods = [
    "Euphoric", "Angry", "Lo-Fi", 
    "Romantic", "Nostalgic", "Energetic", "Inspiring",
    "Chill", "Groovy", "Dreamy", "Epic", "Raw",
  ];

  const [collaborators, setCollaborators] = useState([]);
  const [isValid, setIsValid] = useState(true); // Oder einen initialen Wert entsprechend deiner Logik
  const [inputValue, setInputValue] = useState('');
  const [youtubeLink, setYoutubeLink] = useState('');
  const [isYoutubeValid, setIsYoutubeValid] = useState(true);

  
  const debounce = (func, delay) => {
    let inDebounce;
    return function() {
      const context = this;
      const args = arguments;
      clearTimeout(inDebounce);
      inDebounce = setTimeout(() => func.apply(context, args), delay);
    };
  };
  

// Nutzung von useEffect für Debounce-Logik
useEffect(() => {
  const handler = setTimeout(() => {
    if (youtubeLink.trim() === '') {
      setIsYoutubeValid(true);
    } else {
      const isValidLink = isValidYoutubeLink(youtubeLink);
      setIsYoutubeValid(isValidLink);
    }
  }, 500); // Debounce-Zeit

  return () => {
    clearTimeout(handler);
  };
}, [youtubeLink]);

const handleYoutubeChange = (e) => {
  const newLink = e.target.value;
  setYoutubeLink(newLink); // Aktualisiere den Zustand für den YouTube-Link
};


  
useEffect(() => {
  const fetchCredits = async () => {
    if (auth.currentUser) {
      const userId = auth.currentUser.uid;
      const artistRef = databaseRef(database, `/Artists/${userId}`);
      try {
        const snapshot = await get(artistRef);
        if (snapshot.exists()) {
          const userData = snapshot.val();
          // Überprüfe explizit, ob songCredits und isPremium vorhanden sind und setze entsprechend
          const credits = userData.songCredits !== undefined ? userData.songCredits : 0;
          const premiumStatus = userData.isPremium !== undefined ? userData.isPremium : false;
          setSongCredits(credits);
          setIsPremium(premiumStatus); // Setze den Premium-Status
        } else {
          // Keine Benutzerdaten gefunden, handle entsprechend
          console.error("Keine Benutzerdaten gefunden.");
        }
      } catch (error) {
        console.error("Fehler beim Abrufen der Benutzerdaten:", error);
        // Optional: Fehlerbehandlung
      }
    } else {
      // Nicht authentifiziert, handle entsprechend
      console.error("Benutzer nicht authentifiziert.");
    }
  };

  fetchCredits();
}, []); 
  

  useEffect(() => {
    const handler = setTimeout(() => {
      if (inputValue === '') {
        setIsValid(true); // Setze auf gültig, wenn das Feld leer ist
      } else {
        const isValidLink = isValidSpotifyLink(inputValue);
        setIsValid(isValidLink);
      }
    }, 500); // Debounce-Zeit von 500ms
  
    return () => {
      clearTimeout(handler); // Bereinigung, um den Timeout bei der nächsten Ausführung zu löschen
    };
  }, [inputValue]); // Reagiere auf Änderungen des inputValue
  
  

  const handleCollaboratorChange = (index, event) => {
    const updatedCollaborators = collaborators.map((collaborator, idx) => {
      if (idx === index) {
        return { ...collaborator, type: event.target.value };
      }
      return collaborator;
    });
    setCollaborators(updatedCollaborators);
  };
  
  const addCollaboratorItem = () => {
    setCollaborators([...collaborators, { type: '', name: '', link: '' }]);
  };
  
  const updateCollaboratorItem = (index, field, value) => {
    const newCollaborators = [...collaborators];
    newCollaborators[index][field] = value;
    setCollaborators(newCollaborators);
  };
  
  const removeCollaboratorItem = (index) => {
    const newCollaborators = [...collaborators];
    newCollaborators.splice(index, 1);
    setCollaborators(newCollaborators);
  };



  function isValidSpotifyLink(link) {
    // Prüft nur Spotify-Track-Links mit optionalem "si"-Parameter und nichts anderem danach
    const pattern = /^https:\/\/open\.spotify\.com\/(intl-\w{2}\/)?track\/[a-zA-Z0-9]{22}(\?si=[a-zA-Z0-9]+)?$/;
    return pattern.test(link);
  }
  
  const isValidYoutubeLink = (link) => {
    // Vereinfachtes Muster, das alle vier Link-Typen abdeckt
    const pattern = /^https:\/\/(www|m)\.youtube\.com\/watch\?v=[a-zA-Z0-9_-]+(&.*)?$/;
  
    // Überprüfe den Link gegen das Muster
    return pattern.test(link) ? link : false;
  };
  
  
  

  const handleEquipmentChange = (index, event) => {
    const updatedEquipment = equipment.map((item, idx) => {
      if (idx === index) {
        return { ...item, type: event.target.value };
      }
      return item;
    });
    setEquipment(updatedEquipment);
  };

  const addEquipmentItem = () => {
    setEquipment([...equipment, { type: '', name: '' }]);
  };
  
  const updateEquipmentItem = (index, field, value) => {
    const newEquipment = [...equipment];
    newEquipment[index][field] = value;
    setEquipment(newEquipment);
  };

  const removeEquipmentItem = (index) => {
    const newEquipment = [...equipment];
    newEquipment.splice(index, 1);
    setEquipment(newEquipment);
  };


  const toggleMood = (mood) => {
    setSongData(prev => ({
      ...prev,
      mood: prev.mood.includes(mood) ?
        prev.mood.filter(m => m !== mood) : // Entferne den Mood, wenn er bereits ausgewählt war
        (prev.mood.length < 4 ? [...prev.mood, mood] : prev.mood) // Füge den Mood hinzu, nur wenn weniger als 4 ausgewählt sind
    }));
  };
  

  const handleGenreChange = (e) => {
    setSongData(prev => ({ ...prev, genre: e.target.value }));
  };
  
  const validateLink = debounce((newLink) => {
    if (newLink === '') {
      setIsValid(true); // Setze auf gültig, wenn das Feld leer ist
      return;
    }
  
    const isValidLink = isValidSpotifyLink(newLink);
    setIsValid(isValidLink);
  }, 500); // Verzögerung von 500 Millisekunden
  

// Funktion zum Überprüfen des Spotify-Links innerhalb der SongStepper-Komponente
const handleSpotifyLinkChange = (e) => {
  const newLink = e.target.value;
  setSongData((prevData) => ({
    ...prevData,
    spotifyLink: newLink,
  }));
  setInputValue(newLink); // Aktualisiere den Zustand, der für die Debounce-Logik verwendet wird
};

const shakeAnimation = {
  x: [0, -5, 5, -5, 5, 0], // Reduzierte seitliche Bewegung
  y: [0, -2, 2, -2, 2, 0], // Subtile vertikale Bewegung
  rotate: [0, -2, 2, -2, 2, 0], // Sehr leichte Rotation
  transition: {
    duration: 0.5, // Halte die Dauer moderat für einen sanften Übergang
    ease: "easeInOut", // EaseInOut für sanfte Anfangs- und Endbewegungen
  },
};

  
  const handleNext = () => {
    switch (currentStep) {
      case 1:
        if (songData.songUrl || isValidSpotifyLink(songData.spotifyLink)) setCurrentStep(2);
        else alert("Please upload a song or enter a valid Spotify link.");
        break;
      case 2:
        if (songData.coverUrl) setCurrentStep(3);
        else alert("Please upload a cover image.");
        break;
      case 3:
        if (songData.songName && songData.genre && songData.mood) setCurrentStep(4);
        else alert("Please enter all song details.");
        break;
      case 4:
        if (isValidYoutubeLink(songData.youtubeLink)) console.log("Final Submission", songData); // Hier könnte deine Einreichungslogik stehen
        else alert("Please enter a valid YouTube link.");
        break;
      default:
        alert("An unexpected error has occurred.");
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setSongData(prev => ({ ...prev, [name]: value }));
  };

  const handleFileChange = async (e) => {
    e.preventDefault();
    const file = e.target.files ? e.target.files[0] : e.dataTransfer.files[0];
    const validSongTypes = ['audio/mp3', 'audio/mpeg', 'audio/wav', 'video/x-msvideo']; // 'audio/mp3' kann als 'audio/mpeg' angegeben werden
  
    if (file && validSongTypes.includes(file.type)) {
      setUploading(true);
      const fileExtension = file.name.split('.').pop();
      const fileRef = storageRef(storage, `songFiles/${auth.currentUser.uid}/${new Date().getTime()}.${fileExtension}`);
      try {
        const uploadResult = await uploadBytes(fileRef, file);
        const fileUrl = await getDownloadURL(uploadResult.ref);
        setSongData(prevData => ({ ...prevData, songUrl: fileUrl }));
        setFileName(file.name);
        setUploading(false);
      } catch (error) {
        console.error("Error uploading file: ", error);
        setUploading(false);
      }
    } else {
      alert('Invalid file type. Only MP3, WAV, and AVI files are allowed.');
    }
  };
  

  const handleCoverChange = async (e) => {
    e.preventDefault();
    const file = e.target.files ? e.target.files[0] : e.dataTransfer.files[0];
    const validImageTypes = ['image/jpeg', 'image/png', 'image/webp'];
    
    if (file && validImageTypes.includes(file.type)) {
      try {
        const compressedCoverBlob = await compressImage(file); // Komprimiere das Coverbild
        const compressedCoverFile = new File([compressedCoverBlob], file.name, {
          type: file.type,
        });
  
        setCoverUploading(true);
        const fileRef = storageRef(storage, `coverImages/${auth.currentUser.uid}/${new Date().getTime()}.${file.name.split('.').pop()}`);
        const uploadResult = await uploadBytes(fileRef, compressedCoverFile);
        const fileUrl = await getDownloadURL(uploadResult.ref);
        
        setSongData(prevData => ({ ...prevData, coverUrl: fileUrl }));
        setCoverUploading(false);
      } catch (error) {
        console.error("Error uploading cover image: ", error);
        setCoverUploading(false);
      }
    } else {
      alert('Invalid file type. Only JPEG, PNG, and WEBP files are allowed.');
    }
  };
  
  const isEquipmentValid = () => {
    // Gibt true zurück, wenn jedes Equipment-Item sowohl einen Typ als auch einen Namen hat.
    return equipment.every(item => item.type && item.name.trim() !== "");
  };

  const togglePlay = (event) => {
    event.stopPropagation();
    const audio = audioRef.current;
    audio.src = songData.songUrl; // Set new source
  
    // Add event listener to play audio only when it's ready
    audio.oncanplay = () => {
      if (!isPlaying) {
        audio.play()
          .then(() => {
            setIsPlaying(true);
          })
          .catch((error) => {
            console.error('Error attempting to play:', error);
          });
      } else {
        audio.pause();
        setIsPlaying(false);
      }
    };
  
    audio.addEventListener('timeupdate', () => {
      const progress = (audio.currentTime / audio.duration) * 100;
      setPlaybackProgress(progress);
    });
  };
  



const stepConfig = [
    {
      title: "Upload Song",
      description: "Showcase your talent by uploading your best track.",
    },
    {
      title: "Song Cover Image",
      description: "Use the same cover image as on Spotify or other platforms for consistency.",
    },
    {
      title: "Song Details",
      description: "Describe your song, highlighting its mood, genre, and name.",
    },
    {
      title: "Optional Step",
      description: "Add optional details about your song and make it stand out from the crowd.",
    },
    {
      title: "", // Neuer Schritt für die Bestätigung
      description: "",
    },
  ];
  
  const saveSongInfo = async () => {
    if (uploading) return;
    setUploading(true); // Starte den Upload-Indikator

    const userId = auth.currentUser ? auth.currentUser.uid : null;
  
    if (!userId) {
      alert("User is not logged in.");
      setUploading(false); // Stelle sicher, dass uploading zurückgesetzt wird, falls der Benutzer nicht angemeldet ist
      return;
    }
  
    try {
      const db = getDatabase();
      // Hole artistName, artistCountry und songCredits basierend auf userId
      const artistRef = databaseRef(db, `/Artists/${userId}`);
      const artistSnapshot = await get(artistRef);
      if (!artistSnapshot.exists()) {
        alert("Artist data not found.");
        setUploading(false);
        return;
      }
      const artistData = artistSnapshot.val();
      const { artistName, country, artistType, songCredits } = artistData;
  
      // Berechnen der neuen Song Credits
      let newSongCredits = songCredits > 0 ? songCredits - 1 : 0;
  
      // Erstelle eine neue Song-Referenz in der Datenbank
      const songRef = databaseRef(db, 'Songs');
      const newSongRef = push(songRef);
  
      // Bereite die Daten für die Datenbank vor
      const newSongData = {
        equipment: equipment, // Füge das Array von Ausrüstungen hinzu
        artistID: userId, 
        name: songData.songName,
        artist: artistName,
        genre: songData.genre,
        mood: songData.mood,
        fileUrl: songData.songUrl, 
        musicVideoLink: songData.youtubeLink,
        releaseTimestamp: new Date().toISOString(), 
        selectedCountry: country,
        songCoverUrl: songData.coverUrl,
        spotifyLink: songData.spotifyLink,
        selectedArtistType: artistType,
        collaborators: collaborators,
      };
  
      // Speichere die neuen Daten in der Datenbank
      await set(newSongRef, newSongData);
      console.log("Song erfolgreich gespeichert!");
  
      // Aktualisiere `hasSubmitted` und `songCredits` für den aktuellen Benutzer
      await set(artistRef, { ...artistData, hasSubmitted: true, songCredits: newSongCredits });
  
    } catch (error) {
      console.error("Fehler beim Speichern der Songinformationen oder beim Aktualisieren des Submission Status und der Song Credits: ", error);
      alert("Error saving song information or updating the submission status and song credits.");
    } finally {
      setCurrentStep(stepConfig.length); // Navigiere zum Bestätigungsschritt
      setUploading(false); // Deaktiviere den Upload-Indikator
    }
  };
  
  const isCollaboratorsValid = () => {
    // Returns true if every collaborator has a type, name, and link that are not empty
    return collaborators.every(collaborator => collaborator.type && collaborator.name.trim() !== "" && collaborator.link.trim() !== "");
  };

  if (songCredits === 0 && !isPremium) {
    return <BuyProModal />;
  }

  return (
    <div className="song-stepper-container">
                <GenerativeOrbAnimation5 />
                
               
        <div className="song-stepper-header">
          
            <img src="./img/logo.svg" alt="" />
            <Link to="/dashboard">
            <div style={{marginTop: '0px'}}>
                    <img  src="./img/delete.svg" alt="" />
                    </div>
                </Link>
        </div>
    
      <div className="song-stepper-wrapper-wrapper">
      <SlideStepperSteps 
        currentStep={currentStep} 
        step1Text="Upload Song" 
        step2Text="Cover Image" 
        step3Text="Details" 
        step4Text="Confirm" 
      />
      <div className="song-stepper-wrapper">
 
      <ShowStepperSteps 
        currentStep={currentStep} 
        step1Text="Upload Song" 
        step2Text="Cover Image" 
        step3Text="Details" 
        step4Text="Confirm" 
      />
       
      <div className="song-stepper-titles">
        {/* Dynamisches Rendering basierend auf dem aktuellen Schritt */}
        <div className="left-song-side">
          <h2>{stepConfig[currentStep - 1].title}</h2>
          <p>{stepConfig[currentStep - 1].description}</p>
        </div>
      </div>
      <div className="scrollable-content-wrapper-2" style={{height: 'unset'}}>
        
{currentStep === 1 && (
      <div className="image-upload-container-2">
     <div className="upload-area" style={{maxHeight: '190px'}} onClick={() => !uploading && document.getElementById('song-file-input').click()}>
     <div className="upload-area-frame" style={{height: '170px'}}></div> {/* Frame-Design */}
      {uploading ? (
             <div className="uploading-loader">
             <img className="uploading-image" src="./img/icon.png" alt="" />
             <div className="spinner-3"></div>
           </div>
      ) : fileName ? (
        // Zeigt den Dateinamen an, wenn eine Datei ausgewählt wurde und nicht hochgeladen wird
        <div>
              <img src="../img/waves.svg" alt="" style={{width: '40px', marginBottom: '-10px', filter: 'invert(1)'}}/>
          <p style={{marginBottom: '20px'}}>{fileName}</p> 
        </div>
      ) : (
        // Zeigt das Upload-Symbol und den Text an, wenn keine Datei ausgewählt wurde und nicht hochgeladen wird
        <div className="upload-area-inside">
          <img src="../img/upload.svg" alt="" style={{width: '40px', marginTop: '10px'}}/>
          <p>Click here to upload your song.</p>
        </div>
      )}
      <input 
        id="song-file-input" 
        type="file" 
        onChange={handleFileChange} 
        className="song-file-input" 
        style={{ display: 'none' }} 
        disabled={uploading} 
      />

    {songData.songUrl && !uploading && (
              <div className="song-preview">
                <button onClick={togglePlay} style={{marginLeft: '0px', marginTop: '-5px', width: '41px', height: '35px', border: 'none', backgroundColor: 'black', borderRadius: '100px', marginRight: '10px'}}>
                {isPlaying ? <img src={pauseIcon} style={{marginLeft: '1px'}}className="pause-icon" alt="Pause" /> : <img src={playIcon} className="play-icon" alt="Play" />}
                </button>
                <div className="progress-barro">
                  <div className="progresso" style={{ width: `${playbackProgress}%` }}></div>
                </div>
              </div>
            )}
  </div>

  <div className="upload-spacer">
                <p>OR</p>
  </div>
  <motion.div
  className="song-stepper-input"
  style={{
    border: !isValid ? '1px solid red' : '1px solid grey',
    width: '90%' // Roter Rand bei Ungültigkeit
  }}
  animate={!isValid ? shakeAnimation : {}}
>
  <img src="./img/spotif.webp" alt="" />
  <input
    type="text"
    value={songData.spotifyLink}
    onChange={handleSpotifyLinkChange}
    placeholder="Or paste a Spotify link here"
    style={{
      border: 'none',
      padding: '10px',
      borderRadius: '5px',
      opacity: 1,
      width: '100%',
    }}
  />

</motion.div>
{!isValid && (
  <div style={{ color: 'red', marginTop: '10px', fontSize: '13px' }}>
    Make sure the link has the correct format. <br/>
    e.g. "https://open.spotify.com/intl-de/track/trackId"
  </div>
)}


  
  <button 
    className="welcome-next-button" 
    onClick={handleNext} 
    disabled={!songData.songUrl && !isValidSpotifyLink(songData.spotifyLink)} // Aktualisiert
>
    Next
</button>


 
  </div>
)}

{currentStep === 2 && (
  <>
    <div className="image-upload-container-2">
      <div className="upload-area" onClick={() => !coverUploading && document.getElementById('cover-file-input').click()}>
      <div className="upload-area-frame"></div> {/* Frame-Design */}

        {coverUploading ? (
                <div className="uploading-loader">
                <img className="uploading-image" src="./img/icon.png" alt="" />
                <div className="spinner-3"></div>
              </div>
        ) : songData.coverUrl ? (
            <img src={songData.coverUrl} alt="Cover Preview" style={{width: '100%', height: '100%', objectFit: 'cover', borderRadius: '25px', }} />
            ) : (
              <div className="upload-area-inside">
                <img src="../img/upload.svg" alt="" style={{width: '50px'}}/>
                <p>Click to upload your cover image.</p>
              </div>
            )}
        <input 
          id="cover-file-input" 
          type="file" 
          onChange={handleCoverChange}
          className="cover-file-input" 
          style={{ display: 'none' }} 
        />
      </div>
      <button className="welcome-back-button" onClick={() => setCurrentStep(currentStep - 1)}>Back</button>
      <button className="welcome-next-button" onClick={handleNext} disabled={!songData.coverUrl || coverUploading}>
  Next
</button>

    </div>
  </>
)}
  {currentStep === 3 && (
  <>
    <div className="song-details-container">
      <div className="song-stepper-input" id="song-name-input" style={{ marginBottom: '15px', display: 'flex', marginTop: '15px'}}>
        <input
          type="text"
          name="songName"
          value={songData.songName}
          onChange={handleChange}
          placeholder="Song Name"
          id="input-feld-10"
          className="song-text-input"
          style={{
            marginTop: '20px',
            padding: '15px',
            fontSize: '16px',
            borderRadius: '5px',
            border: '1px solid #ccc',
            width: 'calc(100% - 20px)',
          }}
        />
      </div>
      <div style={{ marginBottom: '15px',}} >
        <SubGenreList
          name="genre"
          value={songData.genre}
          onChange={handleGenreChange}
          className="select-genre-list"
        />
      </div>
      <div style={{ marginBottom: '25px' }}>
          <p style={{width: '100%', textAlign: 'center', fontSize: '14px', color: 'grey', marginTop: '0px'}}>Select Moods</p>
      <div className="mood-selection-container">
            {moods.map((mood) => (
              <button
                key={mood}
                className={`mood-button ${songData.mood.includes(mood) ? "selected" : ""}`}
                onClick={() => toggleMood(mood)}
              >
                {mood}
              </button>
            ))}
            </div>
      </div>
      <button className="welcome-back-button" onClick={() => setCurrentStep(currentStep - 1)}>Back</button>
      <button
        className="welcome-next-button"
        onClick={handleNext}
        disabled={!songData.songName || !songData.genre || songData.mood.length === 0}  // Button wird deaktiviert, wenn nicht alle Felder ausgefüllt sind
      >
        Next
      </button>
    </div>
  </>
)}

{currentStep === 4 && (
  <>
    <div className="song-details-container-2">
      <div>
      <div className="song-youtube-box">

      <h4>Music Video</h4>
      <motion.div
  className="song-stepper-input"
  style={{
    marginBottom: '15px',
    marginTop: '15px',
    border: isYoutubeValid ? '1px solid grey' : '1px solid red', // Roter Rand bei Ungültigkeit
  }}
  animate={!isYoutubeValid ? shakeAnimation : {}}
>
  <img src="./img/yutu_3.webp" style={{marginTop: '4px', width: '35px'}} alt="" />
  <input
  type="text"
  name="youtubeLink"
  value={youtubeLink}
  onChange={handleYoutubeChange}
  placeholder="YouTube Video Link (Optional)"
  className="song-text-input"
  style={{
    marginBottom: '15px',
    marginTop: '0px',
    padding: '10px',
    borderRadius: '5px',
    border: '1px solid #ccc',
    width: 'calc(100% - 20px)',
  }}
/>

</motion.div>
{!isYoutubeValid && youtubeLink !== '' && (
  <div style={{ color: 'red', marginTop: '10px', marginBottom: '15px', fontSize: '14px' }}>
 Make sure the Youtube-Link has the correct format: <br/>
  e.g. "https://www.youtube.com/watch?VideoID"
</div>
)}

      </div>

{/* <div className="collaborators-section" style={{ flexDirection: 'column'}}>
      <h4 className="equip-header-text" style={{marginTop: '10px'}}>Collaborations</h4>
      {collaborators.map((collaborator, index) => (
        <div key={index} className="equipment-input-group-3">
          <label>Collaborator #{index + 1}</label>
          <CollaboratorList 
            value={collaborator.type} 
            onChange={(e) => handleCollaboratorChange(index, e)} 
            className="song-text-input"
          />
          <input 
            type="text" 
            placeholder="Name" 
            value={collaborator.name} 
            onChange={(e) => updateCollaboratorItem(index, 'name', e.target.value)} 
            className="song-text-input-2"
          />
          <input 
            type="text" 
            placeholder="Link to Collaborator" 
            style={{marginTop: '5px'}}
            value={collaborator.link} 
            onChange={(e) => updateCollaboratorItem(index, 'link', e.target.value)} 
            className="song-text-input-2"
          />
          <button 
            type="button" 
            onClick={() => removeCollaboratorItem(index)} 
            className="remove-button-pub"
          >
            <img src="./img/delete.svg" alt="Remove" />
          </button>
        </div>
      ))}
      <button 
        type="button" 
        onClick={addCollaboratorItem} 
        className="add-buttone"
      >
           <p>Add Collaborator</p>
        <img src="./img/plus.svg" alt="" />
      </button>
      </div> */}



      <div className="collaborators-section" style={{paddingBottom: '100px'}}>

      {/* Equipment Section */}
      <h4 className="equip-header-text">Equipment</h4>
      {equipment.map((equip, index) => (
        <div key={index} className="equipment-input-group-3">
          <label>Equipment #{index + 1}</label>
          <EquipmentList 
            value={equip.type} 
            onChange={(e) => handleEquipmentChange(index, e)} 
          />
          <input 
            type="text" 
            placeholder="Equipment Name: e.g. Shure SM57" 
            value={equip.name} 
            onChange={(e) => updateEquipmentItem(index, 'name', e.target.value)} 
            disabled={uploading}
            className="song-text-input-2"
          />
          <button 
            type="button" 
            onClick={() => removeEquipmentItem(index)} 
            disabled={uploading}
            className="remove-button-pub"
          >
            <img src="./img/delete.svg" alt="Remove" />
          </button>
        </div>
      ))}
      <button 
        type="button" 
        onClick={addEquipmentItem} 
        disabled={uploading}
        className="add-buttone"
      >
        <p>Add Equipment</p>
        <img src="./img/plus.svg" alt="" />

      </button>
</div>
      <button className="welcome-back-button" onClick={() => setCurrentStep(currentStep - 1)}>Back</button>
      <button
  className="welcome-next-button"
  onClick={saveSongInfo}
  disabled={
    (songData.youtubeLink && !isValidYoutubeLink(songData.youtubeLink)) ||
    !isEquipmentValid() || 
    !isCollaboratorsValid() // Add this check
  }
>
  Submit
</button>

    </div>
    </div>

  </>
)}

{currentStep === 5 && ( // Achte darauf, dass dies der Index deines Bestätigungsschritts ist (hier 5, da 1-basiert)
        <CompletedStep5 />
)}
      </div>
      </div>
    </div>
    </div>

  );
  
}

export default SongStepper;

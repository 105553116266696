import React, { useState, useEffect } from 'react';
import { auth, database, storage } from '../firebaseConfig';
import { ref as databaseRef, update, get } from 'firebase/database';
import { ref as storageRef, uploadBytes, getDownloadURL } from 'firebase/storage';
import SubGenreList from '../Utility/SubGenreList';
import ArtistTypes from '../Utility/ArtistTypes';
import './WelcomeStepper.css'
import { Link, useNavigate } from 'react-router-dom';
import Resizer from 'react-image-file-resizer';
import { compressImage } from '../Utility/ImageCompressor';
import ToggleSwitch from '../Utility/ToggleSwitch';
import GenerativeOrbAnimation3 from '../Utility/GenerativeOrbAnimation3';

function WelcomeStepper() {

  const initialServices = [
    { name: 'sellBeats', title: 'Sell Beats', checked: false },
    { name: 'sellLicense', title: 'Sell Song Licenses', checked: false },
    { name: 'sellVerse', title: 'Sell Verses', checked: false },
    { name: 'openForCollabs', title: 'Collaborate', checked: false },
  ];
  const navigate = useNavigate(); // Initialisiere useNavigate hier
  const [currentStep, setCurrentStep] = useState(1);
  const [artistData, setArtistData] = useState({
    artistName: '',
    name: '',
    genre: '',
    artistType: '',
    artistImageUrl: '',
    country: '',
    sellBeats: false,
    sellLicense: false,
    sellVerse: false,
    openForCollabs: false,
    sendArtistWelcome: true,
    collabCredits: 3,
  });
  const [uploading, setUploading] = useState(false);
  const [userId, setUserId] = useState(null);
  const [imagePreview, setImagePreview] = useState(null);
  const [services, setServices] = useState(initialServices);
  const [formErrors, setFormErrors] = useState({});
  const [agreedToTerms, setAgreedToTerms] = useState(false);
const [agreedToPrivacy, setAgreedToPrivacy] = useState(false);


  const validateForm = () => {
    let errors = {};
    let formIsValid = true;
  
    // Beispielvalidierung für das Künstlerbild
    if (!artistData.artistImageUrl) {
      errors.artistImageUrl = "Bitte laden Sie ein Bild hoch.";
      formIsValid = false;
    }
  
    // Fügen Sie hier weitere Validierungen hinzu...
  
    setFormErrors(errors);
    return formIsValid;
  };


// Ensure SubGenreList's onChange prop is correctly handled
const handleGenreChange = (event) => {
  const { value } = event.target;
  setArtistData(prevData => ({
    ...prevData,
    genre: value,
  }));
};

const toggleService = (index) => {
  const newServices = services.map((service, i) => {
    if (i === index) {
      return { ...service, checked: !service.checked };
    }
    return service;
  });

  setServices(newServices);
  // Also update the artistData state accordingly
  const updatedData = { ...artistData };
  newServices.forEach((service) => {
    updatedData[service.name] = service.checked;
  });
  setArtistData(updatedData);
};

// For artistType, directly use a select element with options mapped from artistTypes array
const handleArtistTypeChange = (event) => {
  const { value } = event.target;
  setArtistData(prevData => ({
    ...prevData,
    artistType: value,
  }));
};


// For country, directly use a select element with options mapped from countries array
const handleCountryChange = (event) => {
  const { value } = event.target;
  setArtistData(prevData => ({
    ...prevData,
    country: value,
  }));
};


useEffect(() => {
  const unsubscribe = auth.onAuthStateChanged(user => {
    if (user) {
      setUserId(user.uid);
    } else {
      setUserId(null);
    }
  });

  return () => unsubscribe(); // Cleanup-Abonnement, wenn die Komponente demontiert wird
}, []);


useEffect(() => {
  if (auth.currentUser) {
    const userId = auth.currentUser.uid;
    // Pfad zur Überprüfung der Benutzereinstellungen in Firebase
    const settingsRef = databaseRef(database, `/Artists/${userId}/hasSettings`);
    get(settingsRef).then((snapshot) => {
      if (snapshot.exists() && snapshot.val() === true) {
        // Benutzer hat die Einstellungen bereits konfiguriert
        navigate('/dashboard'); // Weiterleitung zum Dashboard
      }
      // Falls 'hasSettings' nicht true ist oder nicht existiert, bleibt der Benutzer im WelcomeStepper
    }).catch((error) => {
      console.error("Fehler beim Abrufen der Benutzereinstellungen:", error);
    });
  }
}, [navigate]);


  const handleNext = () => {
    setCurrentStep((prevStep) => prevStep + 1);
  };

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setArtistData(prev => ({ ...prev, [name]: type === 'checkbox' ? checked : value }));
  };

  

  const handleImageChange = async (e) => {
    e.preventDefault();
    const file = e.target.files ? e.target.files[0] : e.dataTransfer.files[0];
    
    if (!file) {
      alert("Please select a file.");
      return;
    }
  
    const validTypes = ['image/jpeg', 'image/png', 'image/webp'];
    if (!validTypes.includes(file.type)) {
      alert("Only JPG, PNG, and WEBP formats are allowed.");
      setUploading(false);
      return;
    }
  
    if (file.size > 10 * 1024 * 1024) { // 10 MB
      alert("The file size exceeds the maximum limit of 10MB.");
      setUploading(false);
      return;
    }
  
    setUploading(true);
    const reader = new FileReader();
  
    reader.onloadend = () => {
      setImagePreview(reader.result);
  
      // Komprimieren und Größe des Bildes ändern, Konvertierung in JPEG
      Resizer.imageFileResizer(
        file, // Das Originalbild
        1200, // Maximale Breite
        1200, // Maximale Höhe
        'JPEG', // Zielformat
        50, // Qualität
        0, // Rotation
        async (uri) => {
          // Konvertieren der Data-URL in ein Blob-Objekt
          const byteString = atob(uri.split(',')[1]);
          const mimeString = uri.split(',')[0].split(':')[1].split(';')[0];
          const ab = new ArrayBuffer(byteString.length);
          const ia = new Uint8Array(ab);
          for (let i = 0; i < byteString.length; i++) {
            ia[i] = byteString.charCodeAt(i);
          }
          const blob = new Blob([ab], { type: mimeString });
  
          // Hochladen des Blob-Objekts zu Firebase
          const fileRef = storageRef(storage, `artistImages/${auth.currentUser.uid}/${new Date().getTime()}.jpeg`);
          try {
            const uploadResult = await uploadBytes(fileRef, blob);
            const imageUrl = await getDownloadURL(uploadResult.ref);
            setArtistData(prevData => ({ ...prevData, artistImageUrl: imageUrl }));
          } catch (error) {
            console.error("Fehler beim Hochladen des Bildes: ", error);
            alert("An error occurred while uploading the image.");
          } finally {
            setUploading(false);
          }
        },
        'base64' // Verwenden von 'base64', da wir direkt aus einer Data-URL konvertieren
      );
    };
    reader.readAsDataURL(file);
  };
  

  const handleDragOver = (e) => e.preventDefault();

  const handleDrop = (e) => {
    e.preventDefault();
    handleImageChange(e);
  };

  const serviceImages = {
    sellBeats: '../img/music.svg',
    sellLicense: '../img/auction.svg',
    sellVerse: '../img/verses.svg',
    openForCollabs: '../img/hands.svg',
  };

  const isFormValidForCurrentStep = () => {
    switch (currentStep) {
      case 1:
        return artistData.artistName.trim().length >= 3 && artistData.name.trim().length >= 3;
        case 2:
        return artistData.genre.trim() !== '' && artistData.artistType.trim() !== '';
      case 3:
        return imagePreview !== null; // Stelle sicher, dass ein Bild hochgeladen wurde
      case 4:
        return artistData.country.trim() !== '' && artistData.country !== 'Select Country'; // Stelle sicher, dass ein Land ausgewählt wurde
      case 5:
        // Optional: Prüfe, ob mindestens ein Service ausgewählt wurde
        return Object.values(artistData).some(value => typeof value === "boolean" && value);
      default:
        return true;
    }
  };
  
  
const renderServiceOptions = () => {
  return (
    <div className="service-options-container">
      {services.map((service, index) => (
        <div
          key={service.name}
          className={`service-option ${service.checked ? 'checked' : ''}`}
          onClick={() => toggleService(index)}
          id="service-artists-checked"
        >
          <img src={serviceImages[service.name]} alt={service.title} />
          <p>{service.title}</p>
        </div>
      ))}
    </div>
  );
};

const handleBack = () => {
  setCurrentStep(currentStep - 1);
};


  const countries = [
    'China', 'India', 'United States', 'Indonesia', 'Pakistan',
    'Brazil', 'Nigeria', 'Bangladesh', 'Russia', 'Mexico',
    'Japan', 'Ethiopia', 'Philippines', 'Egypt', 'Vietnam',
    'DR Congo', 'Turkey', 'Iran', 'Germany', 'Thailand',
    'United Kingdom', 'France', 'Italy', 'Tanzania', 'South Africa',
    'Myanmar', 'Kenya', 'South Korea', 'Colombia', 'Spain',
    'Uganda', 'Argentina', 'Algeria', 'Sudan', 'Ukraine',
    'Iraq', 'Afghanistan', 'Poland', 'Canada', 'Morocco',
    'Austria', 'Belgium', 'Croatia', 'Denmark', 'Estonia',
    'Finland', 'Greece', 'Hungary', 'Ireland', 'Latvia',
    'Lithuania', 'Luxembourg', 'Netherlands', 'Norway', 'Portugal',
    'Romania', 'Slovakia', 'Slovenia', 'Sweden', 'Switzerland', 'Other'
  ];

  const sortedCountries = countries.sort();
  const countryOptions = ['Select Country', ...sortedCountries];

  const handleSubmit = async () => {

    if (!userId) {
      console.error("No user signed in");
      return;
    }
    if (!validateForm()) {
      console.error("Please fill out all required forms");
      // Stoppen Sie die Einreichung, wenn das Formular ungültig ist
      return;
    }
  

    if (!artistData.artistImageUrl) {
      console.error("Please upload a picture.");
      return;
    }

    try {
      // Setze hasSettings auf true und speichere etwaige weitere Daten
      await update(databaseRef(database, `Artists/${userId}`), {
        ...artistData,
        hasSettings: true,
        collabCredits: 5,
      });
      console.log("Settings saved successfully.");
      navigate('/dashboard'); // Weiterleitung zum Dashboard
    } catch (error) {
      console.error("Fehler beim Speichern der Einstellungen: ", error);
    }
  };

  // Hier kommt die UI für die einzelnen Schritte
  return (
    <div className="welcome-stepper-container" style={{ display: 'flex', minHeight: '100vh' }}>
      <GenerativeOrbAnimation3 />
        <div className="song-stepper-header">
            <img src="./img/logo.svg" alt="" />
            <Link to="/">
            <div>
                <p style={{fontSize: '14px', marginTop: '17px'}}></p>
                    <img src="./img/delete.svg" alt="" />
                    </div>
                </Link>
                        </div>
      
      <div className="welcome-stepper-wrapper">
        
      <div className="left-side-stepper" style={{ flex: 1, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        {/* Hier könnten Sie Beschreibungstexte oder Bilder basierend auf dem aktuellen Schritt einfügen */}
        <div>
          {currentStep === 1 && 
          <div className="left-welcome-side">
            <h2>Let's get started.</h2>
          <p>Let's hit play on your career and get started!</p>
          </div>
          }
          {currentStep === 2 &&
                    <div className="left-welcome-side">
                                  <h2>Artistic Style</h2>
          <p>Share your artistic vibe with us to personalize your experience.</p>
          </div>
          }
          {currentStep === 3 && 
                              <div className="left-welcome-side">
                                        <h2>Profile Picture</h2>
                                        <p>Upload a profile picture to bring your digital persona to life.</p>
        </div>
        }
          {currentStep === 4 && 
                                        <div className="left-welcome-side">
                                          <h2>Select Country</h2>
          <p>Please pick your country from the dropdown menu.</p>
          </div>
          }
          {currentStep === 5 && 
       <div className="left-welcome-side">
                                          <h2>Last Step</h2>
          <p>What else are you interested in?</p>
          </div>
          }
        </div>
      </div>
      <div className="stepper-wrapper" style={{ flex: 1, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
        {/* Schritt-basierte Inhalte */}
        {currentStep === 1 && (
        <div className="select-genre-div">
        <label>Artist Name:</label>
        <input
          type="text"
          name="artistName"
          value={artistData.artistName}
          onChange={handleChange}
          placeholder="e.g. The Skies"
        />
         <label>Name:</label>
    <input
      type="text"
      name="name"
      value={artistData.name}
      onChange={handleChange}
      placeholder="e.g. Luke Smith"
    />
        <button className="welcome-next-button" onClick={handleNext} disabled={!isFormValidForCurrentStep()}>Next</button>
      </div>
      
    )}
    {currentStep === 2 && (
      <div className="select-genre-div">
        <label>Genre:</label>
        <SubGenreList className="select-genre-list" name="genre" value={artistData.genre} onChange={handleGenreChange} />
        <label>Artist Type:</label>
        <ArtistTypes className="select-genre-list" value={artistData.artistType} onChange={handleArtistTypeChange} />
        <button className="welcome-back-button" onClick={handleBack}>Back</button>
        <button className="welcome-next-button" onClick={handleNext} disabled={!isFormValidForCurrentStep()}>Continue</button>
      </div>
)}

{currentStep === 3 && (
  <div className="image-upload-container">
    <div className="upload-area" onDragOver={handleDragOver} onDrop={handleDrop} onClick={() => !uploading && document.getElementById('file-input').click()}>
      {uploading ? (
              <div className="uploading-loader">
              <img className="uploading-image" src="./img/icon.png" alt="" />
              <div className="spinner-3"></div>
            </div>
      ) : imagePreview ? (
        <img src={imagePreview} alt="Preview" className="image-preview-upload" style={{width: '100%', height: '100%', objectFit: 'cover', borderRadius: '25px'}} />
      ) : (
        <div className="upload-prompt">
          <div className="upload-area-inside">
            <img src="../img/upload.svg" alt="" />
            <p>Please drag and drop or click to upload your image.</p>
          </div>
          <div className="upload-area-frame"></div>
        </div>
      )}
      <input id="file-input" type="file" onChange={handleImageChange} style={{ display: 'none' }} disabled={uploading} />
    </div>
    {/* Behalte die Buttons wie sie sind */}
    <button className="welcome-back-button" onClick={handleBack}>Back</button>
    <button 
      className="welcome-next-button" 
      onClick={handleNext} 
      disabled={!imagePreview || uploading}
    >
      Next
    </button>
   
  </div>

)}

{currentStep === 4 && (
  <div className="select-genre-div">
    <label>Country:</label>
    <select className="select-genre-list" name="country" value={artistData.country} onChange={handleCountryChange}>
      {countryOptions.map(country => (
        <option key={country} value={country === 'Select Country' ? '' : country}>{country}</option>
      ))}
    </select>
    {/* Der Button wird nur aktiv, wenn ein Land ausgewählt wurde, das nicht der Platzhalter 'Select Country' ist. */}
    <button className="welcome-back-button" onClick={handleBack}>Back</button>
    <button 
      className="welcome-next-button" 
      onClick={handleNext}
      disabled={artistData.country === '' || artistData.country === 'Select Country'}
    >
      Next
    </button>
  </div>
)}

{currentStep === 5 && (
  <div className="service-options-containers">
    {renderServiceOptions()}
    {/* Button wird nur aktiv, wenn mindestens zwei Services ausgewählt wurden */}
    
    <div className="terms-privacy-checkboxes">
    <div>
  <label>
    <div className="tos-wrapper" style={{ marginBottom: '5px' }}>
      <ToggleSwitch isChecked={agreedToTerms} onChange={() => setAgreedToTerms(!agreedToTerms)} />
      <p className={agreedToTerms ? "agreed" : ""}>
        I agree to the <a href="/terms-of-use" target="_blank">Terms & Conditions</a> and understand that my profile will be publicly visible on aaartists.com.
      </p>
    </div>
  </label>
</div>
<div>
  <label>
    <div className="tos-wrapper">
      <ToggleSwitch isChecked={agreedToPrivacy} onChange={() => setAgreedToPrivacy(!agreedToPrivacy)} />
      <p className={agreedToPrivacy ? "agreed" : ""}>
        Send me notifications and marketing emails. (You can unsubscribe at any time)
      </p>
    </div>
  </label>
</div>

    </div>
    
    <button className="welcome-back-button" onClick={() => setCurrentStep(currentStep - 1)}>Back</button>
    <button
      className="welcome-next-button" 
      onClick={handleSubmit}
      disabled={services.filter(service => service.checked).length < 0 || !agreedToTerms || !agreedToPrivacy}
    >
      Submit Profile
    </button>
       </div>
        )}
      </div>
      </div>
    </div>
  );
}

export default WelcomeStepper;